/** @jsx jsx */
import * as blockLibrary from ".."

import { jsx } from "@trueskin-web/theme"

const Block = ({ block, ...rest }) => {
  const Component = blockLibrary[block.__typename]
  const cmsBlock = block.__typename?.replace(
    /^Strapi_Component(Landing|Blocks|Atoms)/,
    ""
  )

  return (
    <section data-cms-block={cmsBlock}>
      <Component {...block} {...rest} />
    </section>
  )
}

export default Block
