/** @jsx jsx */
import { graphql } from "gatsby"

import {
  Button,
  Container,
  FakeSelect,
  Heading,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import i18next from "@trueskin-web/translations"

import faceIdIcon from "../../images/face-id.svg"
import professionalWomanNurseIcon from "../../images/professional-woman-nurse.svg"
import touchIdIcon from "../../images/touch-id.svg"

import HorizontalSteps from "./horizontal-steps"
import VerticalStep from "./vertical-step"

const howItWorksPageDetails = {
  subtitle: i18next.t("HowItWorks.subtitle"),
  steps: [
    {
      title: i18next.t("HowItWorks.Step1.title"),
      lines: i18next.t("HowItWorks.Step1.lines", { returnObjects: true }),
      icon: faceIdIcon,
    },
    {
      title: i18next.t("HowItWorks.Step2.title"),
      lines: i18next.t("HowItWorks.Step2.lines", { returnObjects: true }),
      icon: touchIdIcon,
    },
    {
      title: i18next.t("HowItWorks.Step3.title"),
      lines: i18next.t("HowItWorks.Step3.lines", { returnObjects: true }),
      icon: professionalWomanNurseIcon,
    },
  ],
}

const HowItWorks = ({ title, primaryCta, backgroundColor }) => {
  return (
    <div sx={{ my: 8 }}>
      <Container sx={{ bg: backgroundColor, py: [6, 8] }}>
        <div
          sx={{
            mb: 7,
          }}
        >
          <Heading size="lg" mb={4} sx={{ gridColumn: [null, "span 4"] }}>
            {title}
          </Heading>

          <div sx={{ fontSize: 18 }}>{howItWorksPageDetails.subtitle}</div>
        </div>

        <div sx={{ display: [null, "none"] }}>
          {howItWorksPageDetails.steps.map((step, index) => (
            <VerticalStep key={index} index={index + 1} step={step} />
          ))}
        </div>

        <div
          sx={{
            display: ["none", "grid"],
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <HorizontalSteps steps={howItWorksPageDetails.steps} />
        </div>

        {primaryCta && (
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              alignItems: "stretch",
              mt: [5, 7],
            }}
          >
            <FakeSelect
              sx={{
                marginLeft: [null, "auto"],
              }}
            />

            <Button
              size="lg"
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: primaryCta.targetUrl,
                }).click()
              }}
              sx={{
                ml: [null, 5],
                mt: [4, 0],
              }}
            >
              {primaryCta.label}
            </Button>
          </div>
        )}
      </Container>
    </div>
  )
}

export default HowItWorks

export const howItWorksFragment = graphql`
  fragment HowItWorksFragment on Strapi_ComponentBlocksHowItWorks {
    title
    backgroundColor
    primaryCta {
      label
      targetUrl
    }
  }
`
