/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment } from "react"

import { Container } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import ContentSection from "../components/content-section"
import { flexAlign } from "../utils/strapi-utils"

const Image = ({
  image: desktopImage,
  smallImage,
  alternateText,
  imageTitle,
  caption,
  imageDescription,
  textMobileVerticalAlign,
  textDesktopVerticalAlign,
  textDesktopHorizontalAlign,
}) => {
  const mobileImage = smallImage || desktopImage

  if (!mobileImage && !desktopImage) {
    return null
  }

  return (
    <Fragment>
      <div
        sx={{
          display: ["block", "none"],
        }}
      >
        <div
          sx={{
            position: "relative",
          }}
        >
          <GatsbyImage
            image={mobileImage.imageFile.childImageSharp.gatsbyImageData}
            title={imageTitle}
            alt={alternateText}
          />
          <div
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <Container
              sx={{
                display: "flex",
                alignItems: flexAlign(textMobileVerticalAlign),
                height: "100%",
                py: "2%",
              }}
            >
              <div>
                <ContentSection
                  title={imageTitle}
                  description={imageDescription}
                />
              </div>
            </Container>
          </div>
        </div>
        {caption && <p sx={{ mt: 4, mb: 0, fontSize: 1 }}>{caption}</p>}
      </div>
      <div
        sx={{
          display: ["none", "block"],
        }}
      >
        <Container>
          <div
            sx={{
              position: "relative",
            }}
          >
            <GatsbyImage
              image={desktopImage.imageFile.childImageSharp.gatsbyImageData}
              title={imageTitle}
              alt={alternateText}
            />
            <div
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <div
                sx={{
                  display: "flex",
                  alignItems: flexAlign(textDesktopVerticalAlign),
                  justifyContent: flexAlign(textDesktopHorizontalAlign),
                  height: "100%",
                  py: "2%",
                  px: 9,
                }}
              >
                <div
                  sx={{
                    maxWidth: "50%",
                  }}
                >
                  <ContentSection
                    title={imageTitle}
                    description={imageDescription}
                  />
                </div>
              </div>
            </div>
          </div>
          {caption && <p sx={{ mt: 4, mb: 0, fontSize: 1 }}>{caption}</p>}
        </Container>
      </div>
    </Fragment>
  )
}

export default Image

export const imageFragment = graphql`
  fragment ImageFragment on Strapi_ComponentAtomsImage {
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    alternateText
    imageTitle: title
    caption
    imageDescription: description
    textMobileVerticalAlign
    textDesktopVerticalAlign
    textDesktopHorizontalAlign
  }
`
