/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment, useEffect, useState } from "react"

import { Container, Heading, Tappable } from "@trueskin-web/components"
import { jsx, useBreakpointIndex } from "@trueskin-web/theme"

const IngredientButton = ({ title, ...props }) => (
  <Tappable
    sx={{
      display: "inline-flex",
      px: 6,
      py: 5,
      width: ["100%", 350],
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "monospace",
      fontSize: 1,
      textTransform: "uppercase",
      letterSpacing: "0.2em",
      whiteSpace: "normal",
      textAlign: "left",
      boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
    }}
    {...props}
  >
    {title}
    <div
      sx={{
        ml: 6,
        fontFamily: "body",
        fontSize: 3,
        letterSpacing: "0.08em",
        whiteSpace: "nowrap",
      }}
    >
      {">>"}
    </div>
  </Tappable>
)

const IngredientPanel = ({ title, description, image, ...props }) => (
  <div
    sx={{
      px: 4,
      py: 5,
      boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
    }}
    {...props}
  >
    <Heading
      sx={{
        fontSize: 6,
        letterSpacing: "0.03em",
      }}
    >
      {title}
    </Heading>
    <GatsbyImage
      image={image.imageFile.childImageSharp.gatsbyImageData}
      alt=""
      sx={{
        mt: 3,
      }}
    />
    <div
      sx={{
        mt: 5,
        mx: 4,
      }}
    >
      {description}
    </div>
  </div>
)

const IngredientCards = ({ ingredients }) => {
  const isMobile = useBreakpointIndex() === 0
  const [selected, setSelected] = useState()

  useEffect(() => {
    if (!isMobile && typeof selected !== "number") {
      setSelected(0)
    }
  }, [isMobile, selected])

  return (
    <div>
      <Container noMobilePadding>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["auto", "350px auto"],
            rowGap: [4, 6],
            columnGap: [undefined, 5],
            pt: [4, 6],
            pb: [4, 0],
          }}
        >
          {ingredients.map(({ title, description, image }, index) => (
            <Fragment key={index}>
              <IngredientButton
                title={title}
                onClick={() => setSelected(index)}
                sx={{
                  gridColumnStart: [undefined, 1],
                  display: [
                    selected === index ? "none" : "inline-flex",
                    "inline-flex",
                  ],
                }}
              />
              <div
                sx={{
                  gridColumnStart: [undefined, 2],
                  gridRow: [undefined, `1 / span ${ingredients.length + 1}`],
                  display: selected !== index ? "none" : undefined,
                }}
              >
                <IngredientPanel
                  title={title}
                  description={description}
                  image={image}
                  sx={{
                    mb: [undefined, 6],
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default IngredientCards

export const ingredientCardsFragment = graphql`
  fragment IngredientCardsFragment on Strapi_ComponentBlocksIngredientCards {
    ingredients {
      title
      description
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
