/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  Container,
  Heading,
  TextLink,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const ProductsHighlight = ({ title, description, products, linkCta }) => {
  return (
    <div sx={{ variant: "layout.sectionMargin" }}>
      <Container>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
            gap: [4, 5],
            mb: [7, 8],
          }}
        >
          <Heading size="lg" sx={{ gridColumn: [null, "span 4"] }}>
            {title}
          </Heading>

          <div sx={{ gridColumn: [null, "span 8"] }}>
            <p sx={{ my: 0 }}>{description}</p>
          </div>
        </div>

        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["repeat(3, 250px)", "repeat(3, 1fr)"],
            gap: 5,
            gridColumn: [null, null, "span 8"],
            mx: [-4, 0],
            mb: [6, 7],
            px: [4, 0],
            overflowX: "auto",
            scrollSnapType: "x mandatory",
          }}
        >
          {products.map((product, index) => (
            <div
              key={index}
              sx={{
                scrollSnapAlign: "center",
                borderBottom: [null, "1px"],
                paddingBottom: [null, 5],
              }}
            >
              <GatsbyImage
                image={product.image.imageFile.childImageSharp.gatsbyImageData}
                alt=""
                sx={{
                  height: [335, "auto"],
                  mb: 5,
                }}
              />

              <Heading as="h3" mb={2}>
                {product.title}
              </Heading>

              <UserGeneratedHtml html={product.content} />
            </div>
          ))}
        </div>

        <div sx={{ display: "flex", justifyContent: [null, "flex-end"] }}>
          <TextLink href={linkCta.targetUrl} hasArrowRight>
            {linkCta.label}
          </TextLink>
        </div>
      </Container>
    </div>
  )
}

export default ProductsHighlight

export const productsHighlightFragment = graphql`
  fragment ProductsHighlightFragment on Strapi_ComponentBlocksProductsHighlight {
    title
    description
    products {
      name
      content
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 400, aspectRatio: 1, layout: CONSTRAINED)
          }
        }
      }
    }
    linkCta {
      label
      targetUrl
    }
  }
`
