/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Container, Heading, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import ListWithDivider, {
  ListWithDividerItem,
} from "./components/list-with-divider"

const Prefooter = ({ title, image, categories }) => {
  return (
    <div sx={{ variant: "layout.sectionMargin" }}>
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
          gap: [null, 7],
        }}
      >
        <GatsbyImage
          image={image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            display: ["none", "block"],
          }}
        />

        <div>
          <Heading size="lg" mb={[2, 4]}>
            {title}
          </Heading>

          <ListWithDivider withArrow>
            {categories.map((category, index) => (
              <ListWithDividerItem
                key={index}
                as={TextLink}
                href={`/${category.slug}/`}
                isTextColor
              >
                {category.name}
              </ListWithDividerItem>
            ))}
          </ListWithDivider>
        </div>
      </Container>
    </div>
  )
}

export default Prefooter

export const prefooterFragment = graphql`
  fragment PrefooterFragment on Strapi_ComponentBlocksPrefooter {
    title
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 50, layout: CONSTRAINED)
        }
      }
    }
    categories {
      name
      slug
    }
  }
`
