/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Container } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import { flexAlign } from "./utils/strapi-utils"

const Connector = ({ image, imgPosition: position, size: { value: size } }) => (
  <div
    sx={{
      position: "relative",
    }}
  >
    <div
      sx={{
        overflow: "hidden",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        height: (theme) => theme.space[size],
        transform: "translateY(-50%)",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: flexAlign(position),
          height: "100%",
        }}
      >
        {image.imageFile.childImageSharp ? (
          <GatsbyImage
            image={getImage(image.imageFile)}
            alt=""
            sx={{
              maxWidth: (theme) => theme.space[size],
              maxHeight: (theme) => theme.space[size],
              transform:
                position === "left"
                  ? "translateX(-50%)"
                  : position === "right"
                  ? "translateX(50%)"
                  : undefined,
            }}
          />
        ) : (
          <img
            src={image.url}
            alt=""
            sx={{
              maxWidth: (theme) => theme.space[size],
              maxHeight: (theme) => theme.space[size],
              transform:
                position === "left"
                  ? "translateX(-50%)"
                  : position === "right"
                  ? "translateX(50%)"
                  : undefined,
            }}
          />
        )}
      </Container>
    </div>
  </div>
)

export default Connector

export const connectorFragment = graphql`
  fragment ConnectorFragment on Strapi_ComponentBlocksConnector {
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    imgPosition: position
    size {
      value
    }
  }
`
