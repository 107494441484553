/** @jsx jsx */
import { Fragment } from "react"

import { jsx } from "@trueskin-web/theme"

import checkmarkIcon from "../../images/checkmark.svg"

const HorizontalSteps = ({ steps }) => (
  <Fragment>
    {steps.map((step, index) => (
      <div
        key={index}
        sx={{
          fontSize: 24,
          display: "flex",
          flexDirection: "column",
          "&:not(:last-of-type)": {
            pr: 5,
          },
          mb: 5,
        }}
      >
        <img
          alt=""
          src={step.icon}
          sx={{ width: 80, height: 80, alignSelf: "center", mb: 7 }}
        />
        <span>{`${index + 1}. ${step.title}`}</span>
      </div>
    ))}

    {steps.map((step) =>
      step.lines.map((line, index) => (
        <div
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            marginInlineStart: "unset",
            "&:not(:last-of-type)": {
              pr: 5,
            },
            mb: 5,
          }}
        >
          <img alt="" sx={{ mr: 24 }} src={checkmarkIcon} />
          {line}
        </div>
      ))
    )}
  </Fragment>
)

export default HorizontalSteps
