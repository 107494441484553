/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import { flexAlign } from "./utils/strapi-utils"

const DoctorTeam = ({
  teamTitle: title,
  teamDescription: description,
  note,
  backgroundColor,
  trustBadge,
  image,
  imagePosition,
  smallImage,
  smallImagePosition,
}) => (
  <div
    sx={{
      display: "flex",
      flexDirection: ["column", "row"],
      position: "relative",
      backgroundColor,
    }}
  >
    {smallImage?.imageFile?.childImageSharp?.gatsbyImageData && (
      <div
        sx={{
          display: ["flex", "none"],
          order: flexAlign(smallImagePosition) === "flex-start" ? 0 : 1,
        }}
      >
        <GatsbyImage
          image={smallImage.imageFile.childImageSharp.gatsbyImageData}
          alt=""
        />
      </div>
    )}
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        sx={{
          display: ["none", "flex"],
          flexBasis: 0,
          flexGrow: 1,
          order: flexAlign(imagePosition) === "flex-start" ? 0 : 1,
        }}
      >
        <GatsbyImage
          image={image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
        />
      </div>
      <div
        sx={{
          flexBasis: 0,
          flexGrow: 1,
          my: [6, 7],
          ml: flexAlign(imagePosition) === "flex-start" ? [null, 8] : undefined,
          mr: flexAlign(imagePosition) === "flex-start" ? undefined : [null, 8],
        }}
      >
        <Heading
          sx={{
            fontSize: [6, 38],
            letterSpacing: "0.03em",
            textAlign: ["center", "left"],
          }}
        >
          {title}
        </Heading>
        <div
          sx={{
            mt: [5, 6],
            "*": {
              letterSpacing: "0.03em",
            },
            "ul, ol": {
              pl: 20,
            },
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {(trustBadge || note) && (
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              mt: [5, 6],
            }}
          >
            {trustBadge && (
              <img
                src={getSrc(trustBadge.image.imageFile)}
                alt="trust badge"
                loading="lazy"
                sx={{
                  maxWidth: 100,
                  maxHeight: 100,
                  mr: 4,
                }}
              />
            )}
            {note && (
              <div
                sx={{
                  fontFamily: "monospace",
                  fontSize: 0,
                }}
              >
                {note}
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  </div>
)

export default DoctorTeam

export const doctorTeamFragment = graphql`
  fragment DoctorTeamFragment on Strapi_ComponentBlocksDoctorTeam {
    teamTitle: title
    teamDescription: description
    note
    backgroundColor
    trustBadge {
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      width
    }
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
    }
    imagePosition
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 400, layout: CONSTRAINED)
        }
      }
    }
    smallImagePosition
  }
`
