/** @jsx jsx */
import { Children, cloneElement } from "react"

import { Icon } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

const ListWithDivider = ({ children, withArrow, hasMargin }) => (
  <ul sx={{ margin: 0, padding: 0, listStyle: "none" }}>
    {children &&
      Children.map(children, (child) =>
        child && child.props
          ? cloneElement(child, {
              icon: child.props.icon
                ? child.props.icon
                : withArrow
                ? arrowRightIcon
                : undefined,
              hasMargin,
            })
          : null
      )}
  </ul>
)

export const ListWithDividerItem = ({
  as: Comp = "div",
  children,
  icon,
  hasMargin,
  ...otherProps
}) => (
  <li
    sx={{
      borderBottom: "1px",
      "&:not(:last-of-type)": {
        mb: [4, hasMargin ? 4 : 0],
      },
    }}
  >
    <Comp
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: [4, 5],
        py: [4, 5],
      }}
      {...otherProps}
    >
      {children}
      {icon && <Icon icon={icon} />}
    </Comp>
  </li>
)

export default ListWithDivider
