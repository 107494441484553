/** @jsx jsx */
import { memo, useEffect, useState } from "react"

import { jsx } from "@trueskin-web/theme"
import { blink } from "@trueskin-web/theme/animations"

const getFixedSentence = (string = "") => string.slice(0, string.indexOf("["))

const getAnimatedSentences = (string = "") =>
  Array.from(string.matchAll(/\[([^\][]*)]/g), (x) => x[1])

const getLongestAnimatedSentence = (words = []) =>
  words.reduce((a, b) => (a.length > b.length ? a : b), "")

const TypeAnimation = ({
  sentences,
  loop = true,
  delayTypeDelete = 2000,
  typeSpeed = 100,
  deleteSpeed = 40,
}) => {
  const [animatingSentence, setAnimatingSentence] = useState("")

  useEffect(() => {
    let timerId

    const typeWrite = (sentenceIndex, letterIndex) => {
      if (!sentences?.length) {
        return
      }

      const sentence = sentences[sentenceIndex]
      if (letterIndex === sentence.length) {
        timerId = setTimeout(() => {
          typeDelete(sentenceIndex, letterIndex)
        }, delayTypeDelete)

        return
      }

      setAnimatingSentence((animatedSentence) =>
        animatedSentence.concat(sentence[letterIndex])
      )

      timerId = setTimeout(() => {
        typeWrite(sentenceIndex, letterIndex + 1)
      }, typeSpeed)
    }

    const typeDelete = (sentenceIndex, letterIndex) => {
      if (letterIndex === -1) {
        if (sentenceIndex === sentences.length - 1 && loop) {
          typeWrite(0, 0)
        } else {
          typeWrite(sentenceIndex + 1, 0)
        }

        return
      }

      setAnimatingSentence((animatedSentence) => animatedSentence.slice(0, -1))

      timerId = setTimeout(() => {
        typeDelete(sentenceIndex, letterIndex - 1)
      }, deleteSpeed)
    }

    typeWrite(0, 0)

    return () => {
      clearTimeout(timerId)
    }
  }, [sentences, loop, delayTypeDelete, typeSpeed, deleteSpeed])

  return (
    <span
      sx={{
        fontWeight: "bold",
      }}
    >
      {animatingSentence}
      <span
        sx={{
          position: "relative",
          "&:after": {
            content: `""`,
            position: "absolute",
            top: 2,
            bottom: 2,
            right: "50%",
            borderRight: "3px solid",
            animation: `${blink} 800ms linear infinite`,
          },
        }}
      >
        &nbsp;
      </span>
    </span>
  )
}

const Typewriter = memo(({ text }) => {
  const fixedSentence = getFixedSentence(text)
  const animatedSentences = getAnimatedSentences(text)
  const longestAnimatedSentence = getLongestAnimatedSentence(animatedSentences)

  return (
    <div
      sx={{
        position: "relative",
      }}
    >
      <div
        sx={{
          visibility: "hidden",
        }}
      >
        {fixedSentence}
        {longestAnimatedSentence && (
          <span sx={{ fontWeight: "bold" }}>
            {longestAnimatedSentence}&nbsp;
          </span>
        )}
      </div>
      <div
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        {fixedSentence}
        {animatedSentences.length !== 0 && (
          <TypeAnimation sentences={animatedSentences} />
        )}
      </div>
    </div>
  )
})

export default Typewriter
