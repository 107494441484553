/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import { CdnImage, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const DoctorsIntro = ({ title, description, image, badge }) => (
  <div sx={{ mt: [5, 7], mb: [9, 10] }}>
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
        alignItems: "center",
        gap: [5, 7],
      }}
    >
      <GatsbyImage
        image={image.imageFile.childImageSharp.gatsbyImageData}
        alt=""
        sx={{
          maxHeight: [400, "initial"],
        }}
      />

      <div>
        <Heading as="h1" size="xl" mb={[4, 5]}>
          {title}
        </Heading>

        <p
          sx={{
            mt: 0,
            mb: 5,
            pb: 5,
            fontSize: 3,
            borderBottom: ["1px", "none"],
          }}
        >
          {description}
        </p>

        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            alignItems: [null, "center"],
          }}
        >
          <CdnImage
            src={getSrc(badge.image.imageFile)}
            alt={badge.title}
            sx={{
              maxWidth: badge.width,
              mb: 4,
              mr: [null, 6],
            }}
          />

          <div>
            <Heading as="h5" size="sm" mb={[2, 1]}>
              {badge.title}
            </Heading>

            <p sx={{ mt: 0, fontSize: [null, 1] }}>{badge.description}</p>
          </div>
        </div>
      </div>
    </Container>
  </div>
)

export default DoctorsIntro

export const doctorsIntroFragment = graphql`
  fragment DoctorsIntroFragment on Strapi_ComponentBlocksDoctorsIntro {
    title
    description
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 600, aspectRatio: 1.25, layout: CONSTRAINED)
        }
      }
    }
    badge {
      title
      description
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      width
    }
  }
`
