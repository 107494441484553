/** @jsx jsx */
import { graphql } from "gatsby"

import { Container, Heading, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

import VerticalStep from "./components/vertical-step"

const HorizontalStep = ({ index, step }) => (
  <div
    sx={{
      position: "relative",
      mt: 3,
      pt: 5,
      flex: 1,
      minWidth: "250px",
      borderTop: "1px",
      "&:not(:last-of-type)": { pr: 7 },
      "&:after": {
        position: "absolute",
        top: "-13px",
        left: 0,
        display: "block",
        width: 22,
        height: 22,
        content: "' '",
        backgroundColor: "lightYellow",
        border: "1px",
      },
    }}
  >
    <div
      sx={{
        variant: "text.caps",
        fontSize: 1,
        mb: 1,
      }}
    >
      <Trans i18nKey="Process.step" values={{ value: index }} />
    </div>

    <Heading as="h4" size="sm">
      {step.title}
    </Heading>

    <div sx={{ mt: 2 }}>{step.description}</div>
  </div>
)

const Process = ({ title, steps, linkCta }) => (
  <div sx={{ variant: "layout.sectionPadding", bg: "lightYellow" }}>
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
          gap: [null, 5],
        }}
      >
        <Heading size="lg" mb={[5, 6]} sx={{ gridColumn: [null, "span 4"] }}>
          {title}
        </Heading>
      </div>

      <div sx={{ display: [null, "none"] }}>
        {steps.map((step, index) => (
          <VerticalStep key={index} index={index + 1} step={step} />
        ))}
      </div>

      <div
        sx={{
          display: ["none", "flex"],
          overflowY: "auto",
          backgroundImage:
            "linear-gradient(to right, #F9F5EA, #F9F5EA), linear-gradient(to right, #F9F5EA, #F9F5EA), linear-gradient(to right, rgba(0,0,0,.15), rgba(255,255,255,0)), linear-gradient(to left, rgba(0,0,0,.15), rgba(255,255,255,0))",
          backgroundPosition:
            "left center, right center, left center, right center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "lightYellow",
          backgroundSize: "20px 100%, 20px 100%, 10px 100%, 10px 100%",
          backgroundAttachment: "local, local, scroll, scroll",
        }}
      >
        {steps.map((step, index) => (
          <HorizontalStep key={index} index={index + 1} step={step} />
        ))}
      </div>

      <div
        sx={{
          display: "flex",
          justifyContent: [null, "flex-end"],
          paddingTop: 50,
        }}
      >
        <TextLink href={linkCta.targetUrl} hasArrowRight>
          {linkCta.label}
        </TextLink>
      </div>
    </Container>
  </div>
)

export default Process

export const processFragment = graphql`
  fragment ProcessFragment on Strapi_ComponentBlocksProcess {
    title
    steps {
      title
      description
    }
    linkCta {
      label
      targetUrl
    }
  }
`
