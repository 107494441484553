/** @jsx jsx */
import { graphql } from "gatsby"

import {
  Button,
  Container,
  Heading,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const TextHero = ({ title, content, primaryCta }) => (
  <div sx={{ mt: [7, 9], mb: [7, 10] }}>
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
          gap: [4, 5],
        }}
      >
        <div sx={{ gridColumn: [null, "span 5"] }}>
          <Heading as="h1" mb={[0, 7]} sx={{ fontSize: [6, 7] }}>
            {title}
          </Heading>

          <Button
            size="lg"
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: primaryCta.targetUrl,
              }).click()
            }}
            sx={{
              display: ["none", "inline-flex"],
              width: "100%",
            }}
          >
            {primaryCta.label}
          </Button>
        </div>

        <div sx={{ gridColumn: [null, "7 / span 6"] }}>
          <UserGeneratedHtml html={content} sx={{ mb: [6, 0] }} />

          <Button
            size="lg"
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: primaryCta.targetUrl,
              }).click()
            }}
            sx={{
              display: [null, "none"],
              width: "100%",
            }}
          >
            {primaryCta.label}
          </Button>
        </div>
      </div>
    </Container>
  </div>
)

export default TextHero

export const textHeroFragment = graphql`
  fragment TextHeroFragment on Strapi_ComponentBlocksTextHero {
    title
    content
    primaryCta {
      label
      targetUrl
    }
  }
`
