/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import { Button, CdnImage, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const DoctorsGrid = ({ doctors = [], trustBadge }) => (
  <div>
    <div
      sx={{
        width: ["100%", 375],
        display: "inline-grid",
        gridTemplateColumns: "repeat(3, 1fr)",
      }}
    >
      {doctors.map(({ title, doctor }) => (
        <div
          key={title}
          sx={{
            position: "relative",
          }}
        >
          <GatsbyImage
            image={doctor.image.imageFile.childImageSharp.gatsbyImageData}
            alt=""
          />
          <Heading
            as="h4"
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              textAlign: "center",
              backgroundColor: "primary",
              color: "white",
              fontSize: 1,
              py: 1,
            }}
          >
            {title}
          </Heading>
        </div>
      ))}
      <div
        sx={{
          display: "inline-grid",
          alignContent: "center",
          justifyContent: "center",
          gridColumnStart: (doctors.length % 3) + 1,
          gridColumnEnd: 4,
          backgroundColor: "blue",
          minHeight: "100px",
        }}
      >
        {trustBadge && (
          <CdnImage
            src={getSrc(trustBadge.image.imageFile)}
            alt="trust badge"
            sx={{
              maxWidth: 100,
              maxHeight: 100,
            }}
          />
        )}
      </div>
    </div>
  </div>
)

const DoctorsInfo = ({ description, cta }) => (
  <div>
    {description && <div sx={{ whiteSpace: "pre-wrap" }}>{description}</div>}
    {cta && (
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          justifyContent: "flex-end",
          mt: [4, 8],
        }}
      >
        <Button
          onClick={() => {
            Object.assign(document.createElement("a"), {
              href: cta.targetUrl,
            }).click()
          }}
        >
          {cta.label}
        </Button>
      </div>
    )}
  </div>
)

const DoctorsBoard = ({
  title,
  subtitle,
  description,
  doctors,
  cta,
  trustBadge,
}) => (
  <div sx={{ variant: "layout.sectionPadding" }}>
    <Container>
      <Heading size="lg">{title}</Heading>
      <p>{subtitle}</p>
    </Container>

    <div
      sx={{
        display: ["block", "none"],
      }}
    >
      <DoctorsGrid doctors={doctors} trustBadge={trustBadge} />
      <Container
        sx={{
          pt: 4,
        }}
      >
        <DoctorsInfo description={description} cta={cta} />
      </Container>
    </div>
    <Container
      sx={{
        display: ["none", "block"],
      }}
    >
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          gridGap: 7,
        }}
      >
        <DoctorsGrid doctors={doctors} trustBadge={trustBadge} />
        <DoctorsInfo description={description} cta={cta} />
      </div>
    </Container>
  </div>
)

export default DoctorsBoard

export const doctorsBoardFragment = graphql`
  fragment DoctorsBoardFragment on Strapi_ComponentBlocksDoctorsBoard {
    title
    subtitle
    description
    doctors {
      title
      doctor {
        name
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 150, aspectRatio: 1, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    cta {
      label
      targetUrl
    }
    trustBadge {
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      width
    }
  }
`
