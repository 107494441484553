/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Button, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const SetIntro = ({
  image,
  title,
  introDescription: description,
  cta,
  ctaDescription,
  introPrice: price,
  priceTitle,
  priceSubtitle,
}) => (
  <div
    sx={{
      py: [6, 7],
    }}
  >
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateAreas: [
            `"heading heading" "price image" "content content" "cta cta"`,
            `"heading image" "price image" "content image" "cta image"`,
          ],
          gridGap: [3, 7],
          alignItems: "center",
        }}
      >
        <Heading
          sx={{
            gridArea: "heading",
            alignSelf: "end",
            fontSize: [32, 38],
            textAlign: ["center", "left"],
          }}
        >
          {title}
        </Heading>
        <div
          sx={{
            gridArea: "content",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            sx={{
              ul: {
                pl: 18,
                m: [null, 0],
              },
            }}
          />
        </div>
        <div
          sx={{
            gridArea: "price",
          }}
        >
          <div
            sx={{
              display: ["block", "inline-block"],
              position: "relative",
              textAlign: "center",
              px: [null, 8],
              py: [4, 5],
              ":before, :after": {
                content: `""`,
                position: "absolute",
                zIndex: -1,
                width: [60, 130],
                height: [40, 60],
              },
              ":before": {
                top: "-1px",
                left: "-1px",
                boxShadow: "-1px -1px 0px 0px",
              },
              ":after": {
                bottom: "-1px",
                right: "-1px",
                boxShadow: "1px 1px 0px 0px",
              },
            }}
          >
            <div
              sx={{
                variant: "text.caps",
                fontFamily: "monospace",
                fontSize: 1,
              }}
            >
              {priceTitle}
            </div>
            <div
              sx={{
                variant: "text.caps",
                fontSize: [6, 7],
                letterSpacing: "0.03em",
              }}
            >
              {price}
            </div>
            <div
              sx={{
                variant: "text.caps",
                fontFamily: "monospace",
                fontSize: [0, 1],
              }}
            >
              {priceSubtitle}
            </div>
          </div>
        </div>
        <div
          sx={{
            gridArea: "cta",
            alignSelf: "start",
            width: ["100%", "fit-content"],
          }}
        >
          <Button
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: cta.targetUrl,
              }).click()
            }}
            sx={{
              width: ["100%", "auto"],
              px: [null, 100],
            }}
          >
            {cta.label}
          </Button>

          {ctaDescription && (
            <div
              sx={{
                mt: [1, 2],
                fontSize: 1,
                textAlign: "center",
              }}
            >
              {ctaDescription}
            </div>
          )}
        </div>
        <div
          sx={{
            gridArea: "image",
            textAlign: "center",
          }}
        >
          <GatsbyImage
            image={image.imageFile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>
      </div>
    </Container>
  </div>
)

export default SetIntro

export const setIntroFragment = graphql`
  fragment SetIntroFragment on Strapi_ComponentBlocksSetIntro {
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 450, layout: CONSTRAINED)
        }
      }
    }
    title
    introDescription: description
    cta {
      label
      targetUrl
    }
    ctaDescription
    introPrice: price
    priceTitle
    priceSubtitle
  }
`
