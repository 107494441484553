/** @jsx jsx */
import ConditionalWrap from "conditional-wrap"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import {
  Carousel,
  CdnImage,
  Container,
  Tappable,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { textClamp } from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"

const PrCard = ({
  link,
  quote,
  image,
  color,
  mobileSize,
  size,
  isFirst,
  isLast,
}) => (
  <div
    sx={{
      mx: [2, 4],
    }}
  >
    <ConditionalWrap
      condition={link}
      wrap={(children) => (
        <Tappable
          onClick={() => {
            Object.assign(document.createElement("a"), {
              target: "_blank",
              href: link,
            }).click()
          }}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: isFirst ? "left" : isLast ? "right" : "center",
          }}
        >
          {children}
        </Tappable>
      )}
    >
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isFirst ? "left" : isLast ? "right" : "center",
          height: (theme) => [
            theme.space[mobileSize] || "100%",
            theme.space[size] || "100%",
          ],
        }}
      >
        <CdnImage
          src={
            image.imageFile.childImageSharp
              ? getSrc(image.imageFile)
              : image.url
          }
          alt="logo"
          sx={{
            maxWidth: "100%",
            maxHeight: "100%",
            height: image.imageFile.childImageSharp ? undefined : "100%",
          }}
        />
      </div>
      {quote && (
        <div
          sx={{
            color,
            borderTopColor: color,
            borderTop: "1px solid",
            mt: [2, 4],
            pt: [1, 2],
            textAlign: "center",
            whiteSpace: "normal",
            fontSize: [1, 3],
            lineHeight: ["16px", "27px"],
          }}
        >
          {textClamp(quote, 85)}
        </div>
      )}
    </ConditionalWrap>
  </div>
)

const PrStrip = ({
  enabled,
  prDescription,
  color,
  backgroundColor,
  fullWidth,
  mobileSize,
  size,
  isModern,
  items,
}) => {
  if (!enabled || !(items?.length > 0)) {
    return null
  }

  return (
    <div
      sx={{
        backgroundColor: [
          backgroundColor,
          fullWidth ? backgroundColor : "transparent",
        ],
      }}
    >
      <Container>
        <div
          sx={{
            color,
            backgroundColor,
            py: (theme) => [
              theme.space[mobileSize?.value] / 2,
              theme.space[size?.value] / 2,
            ],
          }}
        >
          {prDescription && (
            <UserGeneratedHtml
              html={prDescription}
              sx={{
                display: ["block", "none"],
                mb: 2,
                mx: 2,
                fontSize: 10,
                lineHeight: 1,
              }}
            />
          )}
          <Carousel
            slidesToShow={items.length > 4 ? 4 : items.length}
            slidesToScroll={items.length > 4 ? 4 : items.length}
            text={false}
            indicator={false}
            layoutOutline={false}
            sx={{
              display: ["block", "none!important"],
            }}
          >
            {items.map((info, index) => (
              <PrCard
                key={index}
                color={color}
                mobileSize={mobileSize?.value}
                size={size?.value}
                isFirst={index === 0}
                isLast={index === items.length - 1}
                {...info}
              />
            ))}
          </Carousel>
          <div
            sx={{
              display: ["none", "flex"],
              justifyContent: "center",
            }}
          >
            {prDescription && (
              <UserGeneratedHtml
                html={prDescription}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mx: 4,
                  fontSize: 10,
                }}
              />
            )}
            {items.map((info, index) => (
              <PrCard
                key={index}
                color={color}
                mobileSize={mobileSize?.value}
                size={size?.value}
                {...info}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PrStrip

export const prStripFragment = graphql`
  fragment PrStripFragment on Strapi_ComponentBlocksPrStrip {
    enabled
    prDescription: description
    color
    backgroundColor
    fullWidth
    items {
      link
      quote
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(height: 64, layout: FIXED)
          }
        }
      }
    }
    size {
      value
    }
    mobileSize {
      value
    }
    isModern
  }
`
