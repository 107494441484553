/** @jsx jsx */
import { graphql } from "gatsby"
import { Fragment } from "react"
import { Helmet } from "react-helmet"

import { Container } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const InstagramWidget = ({ elfsightAppId, isFullWidth }) => (
  <Fragment>
    {isFullWidth ? (
      <div className={elfsightAppId}></div>
    ) : (
      <Container>
        <div className={elfsightAppId}></div>
      </Container>
    )}

    <Helmet>
      <script src="https://apps.elfsight.com/p/platform.js" defer />
    </Helmet>
  </Fragment>
)

export default InstagramWidget

export const instagramWidgetFragment = graphql`
  fragment InstagramWidgetFragment on Strapi_ComponentBlocksInstagramWidget {
    elfsightAppId
    isFullWidth
  }
`
