/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Button, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import AppStoreButtons from "./components/app-store-buttons"
import ContentSection from "./components/content-section"

const SmallHero = ({
  title,
  description,
  smallImage,
  image,
  primaryCta,
  appleStoreURL,
  googleStoreURL,
  ctaDescription,
}) => (
  <div sx={{ mt: [5, 7], mb: [9, 8] }}>
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
          gap: [6, 5],
          position: "relative",
          minHeight: [null, 352],
        }}
      >
        <GatsbyImage
          image={smallImage.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            display: [null, "none"],
            maxHeight: 300,
          }}
        />

        <GatsbyImage
          image={image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            display: ["none", "block"],
            position: "absolute !important",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
          }}
        />

        <div
          sx={{
            display: [null, "flex"],
            flexDirection: [null, "column"],
            justifyContent: [null, "center"],
            gridColumn: [null, "2 / span 7"],
            maxWidth: [null, 680],
            py: [null, 7],
          }}
        >
          <Heading
            as="h1"
            mb={[3, 5]}
            sx={{
              fontSize: [6, 7],
            }}
          >
            {title}
          </Heading>

          <div sx={{ mb: [6, 7], maxWidth: [null, 500], fontSize: 3 }}>
            <ContentSection description={description} />
          </div>

          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
            }}
          >
            <Button
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: primaryCta.targetUrl,
                }).click()
              }}
            >
              {primaryCta.label}
            </Button>
          </div>

          {ctaDescription && (
            <div
              sx={{
                mt: 2,
                fontSize: 1,
                textAlign: ["center", "left"],
              }}
            >
              {ctaDescription}
            </div>
          )}

          {appleStoreURL && googleStoreURL ? (
            <AppStoreButtons
              appleStoreURL={appleStoreURL}
              googleStoreURL={googleStoreURL}
              sx={{ mx: ["auto", 0] }}
            />
          ) : null}
        </div>
      </div>
    </Container>
  </div>
)

export default SmallHero

export const smallHeroFragment = graphql`
  fragment SmallHeroFragment on Strapi_ComponentBlocksSmallHero {
    title
    description
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, aspectRatio: 2.17, layout: CONSTRAINED)
        }
      }
    }
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 3.18, layout: FULL_WIDTH)
        }
      }
    }
    primaryCta {
      label
      targetUrl
    }
    appleStoreURL
    googleStoreURL
    ctaDescription
  }
`
