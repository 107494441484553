/** @jsx jsx */
import { graphql } from "gatsby"
import { navigate } from "gatsby-link"
import { getSrc } from "gatsby-plugin-image"

import {
  Button,
  CdnImage,
  Container,
  Heading,
  Icon,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

const TreatmentGoals = ({ title, items }) => (
  <div
    sx={{
      mb: 9,
    }}
  >
    <Container>
      <Heading
        sx={{
          fontSize: 6,
          mb: 6,
        }}
      >
        {title}
      </Heading>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
          gap: [5, 7],
          px: [null, 7],
        }}
      >
        {items.map(({ label, link, image }, index) => (
          <div
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CdnImage
              src={getSrc(image.imageFile)}
              alt={label}
              sx={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
            <Button
              variant="outline"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => {
                navigate(link)
              }}
            >
              <Heading
                as="h3"
                sx={{
                  fontSize: 4,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: ["calc(100vw - 90px)", 420],
                }}
              >
                {label}
              </Heading>
              <Icon
                icon={arrowRightIcon}
                size="lg"
                sx={{
                  position: "relative",
                  left: 3,
                }}
              />
            </Button>
          </div>
        ))}
      </div>
    </Container>
  </div>
)

export default TreatmentGoals

export const treatmentGoalsFragment = graphql`
  fragment TreatmentGoalsFragment on Strapi_ComponentBlocksTreatmentGoals {
    title
    items {
      label
      link
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 500, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
