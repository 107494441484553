/** @jsx jsx */
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import { CdnImage, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

export const BadgeItem = ({ title, description, image, width }) => (
  <div
    sx={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <CdnImage
      src={getSrc(image.imageFile)}
      alt={title}
      sx={{
        flexShrink: 0,
        maxWidth: width,
        mr: 4,
      }}
    />

    <div>
      <Heading as="h5" size="sm" mb={1}>
        {title}
      </Heading>

      <p sx={{ my: 0, fontSize: 1 }}>{description}</p>
    </div>
  </div>
)

const Badges = ({ badges }) => (
  <div
    sx={{
      mt: [7, 0],
      mb: [9, 0],
      pt: [null, 8],
      pb: [null, 7],
      bg: [null, "lightGray"],
    }}
  >
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(3, 1fr)"],
          gap: [6, 5],
        }}
      >
        {badges.map((badge) => (
          <BadgeItem
            key={badge.title}
            title={badge.title}
            description={badge.description}
            image={badge.image}
            width={badge.width}
          />
        ))}
      </div>
    </Container>
  </div>
)

export default Badges

export const badgesFragment = graphql`
  fragment BadgesFragment on Strapi_ComponentLandingBadges {
    badges {
      title
      description
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      width
    }
  }
`
