export const flexAlign = (alignment) => {
  switch (alignment) {
    case "top":
    case "left":
      return "flex-start"
    case "middle":
    case "center":
      return "center"
    case "bottom":
    case "right":
      return "flex-end"
    default:
      return null
  }
}
