/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  CapsBar,
  Container,
  DashList,
  DashListItem,
  Heading,
  TextLink,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Slugs, Trans } from "@trueskin-web/translations"

const ShowcaseProductItem = ({ product, backgroundColor, imageOnTheRight }) => {
  return (
    <div
      sx={{
        variant: backgroundColor
          ? "layout.sectionPadding"
          : "layout.sectionMargin",
        bg: backgroundColor,
      }}
    >
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
          alignItems: "flex-start",
          gap: [5, 7],
        }}
      >
        <Heading size="lg" sx={{ display: [null, "none"] }}>
          {product.name}
        </Heading>

        <GatsbyImage
          image={product.image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            maxHeight: [400, "initial"],
            order: [null, imageOnTheRight ? 1 : null],
          }}
        />

        <div>
          <Heading size="lg" sx={{ display: ["none", "block"], mb: 4 }}>
            {product.name}
          </Heading>

          <UserGeneratedHtml html={product.content} sx={{ mb: 5 }} />

          <CapsBar>
            <Trans i18nKey="ShowcaseProductItem.ourIngredients" />
          </CapsBar>

          <DashList sx={{ mb: 5, py: 5, borderBottom: "1px" }}>
            {product.ingredients.map((ingredient) => (
              <DashListItem key={ingredient.title}>
                {ingredient.title}
                {ingredient.alias && ` (${ingredient.alias})`}
              </DashListItem>
            ))}
          </DashList>

          <TextLink href={Slugs.ingredients.url} hasArrowRight>
            <Trans i18nKey="ShowcaseProductItem.learnMoreLink" />
          </TextLink>
        </div>
      </Container>
    </div>
  )
}

export default ShowcaseProductItem

export const showcaseProductItemFragment = graphql`
  fragment ShowcaseProductItemFragment on Strapi_ComponentBlocksShowcaseProductItem {
    product {
      name
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 600, aspectRatio: 1, layout: CONSTRAINED)
          }
        }
      }
      content
      ingredients {
        title
        alias
      }
    }
    imageOnTheRight
    backgroundColor
  }
`
