/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import throttle from "lodash/throttle"
import { useCallback, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"

import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

const BeforeAfter = ({ title, description, beforeImage, afterImage }) => {
  const imageWrapperRef = useRef(null)
  const [inViewRef, isInView] = useInView({
    threshold: 0,
  })
  const [afterOpacity, setAfterOpacity] = useState(0)

  const setRefs = useCallback(
    (node) => {
      imageWrapperRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  useEffect(() => {
    if (isInView) {
      const onScroll = throttle(() => {
        const { height, y } = imageWrapperRef.current.getBoundingClientRect()
        const opacity =
          0.6 -
          (y - window.innerHeight / 3) / (0.66 * window.innerHeight - height)

        setAfterOpacity(Math.min(Math.max(0, opacity), 1))
      })

      onScroll()
      window.addEventListener("scroll", onScroll, {
        capture: true,
        passive: true,
      })
      return () => window.removeEventListener("scroll", onScroll, true)
    }
  }, [isInView])

  return (
    <div sx={{ variant: "layout.sectionMargin" }}>
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
          gap: 5,
        }}
      >
        <div
          sx={{
            order: [null, 1],
            gridColumn: [null, "span 4"],
            paddingLeft: [null, 5],
          }}
        >
          <Heading size="lg" mb={4}>
            {title}
          </Heading>

          <p sx={{ my: 0, fontSize: [null, 3] }}>{description}</p>
        </div>

        <div
          sx={{
            position: "relative",
            paddingLeft: [null, 7],
            gridColumn: [null, "span 8"],
          }}
        >
          <div
            sx={{
              position: [null, "absolute"],
              left: [null, 0],
              fontSize: 5,
              color: "primary",
              mb: 4,
              writingMode: [null, "vertical-rl"],
            }}
          >
            <Trans i18nKey="BeforeAfter.before" />
          </div>

          <div ref={setRefs} sx={{ position: "relative" }}>
            <GatsbyImage
              image={beforeImage.imageFile.childImageSharp.gatsbyImageData}
              alt=""
              sx={{
                maxHeight: [350, "initial"],
              }}
            />

            <div
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                overflow: "hidden",
                opacity: afterOpacity,
              }}
            >
              <GatsbyImage
                image={afterImage.imageFile.childImageSharp.gatsbyImageData}
                alt=""
              />
            </div>
          </div>

          <div
            sx={{
              position: [null, "absolute"],
              right: [null, -7],
              bottom: [null, 0],
              fontSize: 5,
              mt: 4,
              textAlign: "right",
              writingMode: [null, "vertical-rl"],
            }}
          >
            <Trans i18nKey="BeforeAfter.after" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default BeforeAfter

export const beforeAfterFragment = graphql`
  fragment BeforeAfterFragment on Strapi_ComponentBlocksBeforeAfter {
    title
    description
    beforeImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED)
        }
      }
    }
    afterImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED)
        }
      }
    }
  }
`
