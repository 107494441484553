/** @jsx jsx */
import { graphql } from "gatsby"

import {
  Container,
  ExpansionPanel,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import FaqContainer from "../components/faq-container"

const Faqs = ({ items }) =>
  items &&
  items.length && (
    <div sx={{ mt: [null, null], mb: 9 }}>
      <Container>
        <FaqContainer>
          {items.map(({ question, answer }, index) => (
            <ExpansionPanel key={index} title={question}>
              <UserGeneratedHtml html={answer} />
            </ExpansionPanel>
          ))}
        </FaqContainer>
      </Container>
    </div>
  )

export default Faqs

export const faqsFragment = graphql`
  fragment FaqsFragment on Strapi_ComponentBlocksFaqs {
    items {
      question
      answer
    }
  }
`
