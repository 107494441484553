/** @jsx jsx */
import { graphql } from "gatsby"

import { UserGeneratedHtml } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const RichContent = ({ richContent, ...rest }) => (
  <UserGeneratedHtml html={richContent} {...rest} />
)

export default RichContent

export const richContentFragment = graphql`
  fragment RichContentFragment on Strapi_ComponentAtomsRichContent {
    richContent: content
  }
`
