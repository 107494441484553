/** @jsx jsx */
import { graphql } from "gatsby"

import { jsx } from "@trueskin-web/theme"

import ShowcaseProductItem from "./showcase-product-item"

const ShowcaseProducts = ({ items }) =>
  items.map((product, index) => (
    <ShowcaseProductItem key={index} {...product} />
  ))

export default ShowcaseProducts

export const showcaseProductsFragment = graphql`
  fragment ShowcaseProductsFragment on Strapi_ComponentBlocksShowcaseProducts {
    items {
      product {
        name
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
        content
        ingredients {
          title
          alias
        }
      }
      imageOnTheRight
      backgroundColor
    }
  }
`
