/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import appleAppStoreIcon from "../../images/app-store-badge.svg"
import googlePlayIcon from "../../images/google-play-badge.png"

const AppleAppStoreButton = ({ url }) => (
  <a
    href={url}
    title="Apple App Store"
    target="_blank"
    rel="noopener noreferrer"
    sx={{ textDecoration: "none" }}
  >
    <img
      src={appleAppStoreIcon}
      alt="Apple App Store"
      loading="lazy"
      sx={{ height: 40, width: 124 }}
    />
  </a>
)

const GooglePlayButton = ({ url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    title="Google Play"
    sx={{ textDecoration: "none" }}
  >
    <img
      src={googlePlayIcon}
      alt="Google Play"
      loading="lazy"
      sx={{ height: 53, width: 137 }}
    />
  </a>
)

const AppStoreButtons = ({ appleStoreURL, googleStoreURL, className }) => (
  <div
    sx={{
      py: 4,
      maxWidth: [280, "initial"],
      display: "flex",
      alignItems: "center",
      gap: [4, 2],
    }}
    className={className}
  >
    <AppleAppStoreButton url={appleStoreURL} />
    <GooglePlayButton url={googleStoreURL} />
  </div>
)

export default AppStoreButtons
