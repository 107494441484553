/** @jsx jsx */
import { Fragment } from "react"

import { jsx } from "@trueskin-web/theme"

import Block from "./block"

const Blocks = ({ blocks = [], ...rest }) => (
  <Fragment>
    {blocks.map((block, index) => {
      const isFirstSupported =
        index === 0 &&
        [
          "Strapi_ComponentBlocksHero",
          "Strapi_ComponentBlocksFullSizeHero",
          "Strapi_ComponentBlocksLpHero",
        ].includes(block.__typename)

      return (
        <Block
          key={index}
          block={block}
          {...rest}
          {...(isFirstSupported && { blockIsFirst: true })}
        />
      )
    })}
  </Fragment>
)

export default Blocks
