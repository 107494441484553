/** @jsx jsx */
import { getSrc } from "gatsby-plugin-image"

import { jsx } from "@trueskin-web/theme"

const ContentPrStrip = ({ title, items, className }) => {
  if (!title && !items.length) {
    return null
  }

  return (
    <div className={className}>
      {title && (
        <div
          sx={{
            fontSize: 12,
          }}
        >
          {title}
        </div>
      )}
      {items.map(({ image, alternateText }, index) => (
        <div
          key={index}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            height: 15,
            mr: 2,
          }}
        >
          <img
            src={
              image.imageFile.childImageSharp
                ? getSrc(image.imageFile)
                : image.url
            }
            alt={alternateText}
            loading="lazy"
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default ContentPrStrip
