/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Button, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const Highlights = ({ title, description, items, primaryCta, onHomepage }) => {
  return (
    <div sx={{ variant: "layout.sectionPadding", bg: "lightRose" }}>
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", null, "repeat(12, 1fr)"],
          gap: [7, null, 5],
          alignItems: [null, null, "flex-start"],
        }}
      >
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["auto", "repeat(12, 1fr)", "auto"],
            gap: [4, 5],
            gridColumn: [null, null, "span 4"],
            order: [null, null, 1],
            pl: [null, null, 5],
          }}
        >
          <Heading size="lg" sx={{ gridColumn: [null, "span 5", "unset"] }}>
            {title}
          </Heading>

          <div sx={{ gridColumn: [null, "span 7", "unset"] }}>
            <p sx={{ mt: 0, mb: [0, 6], fontSize: 3 }}>{description}</p>

            <Button
              isFullWidth
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: primaryCta.targetUrl,
                }).click()
              }}
              {...(onHomepage && {
                id: "highlights-cta",
              })}
              sx={{ display: ["none", "inline-flex"] }}
            >
              {primaryCta.label}
            </Button>
          </div>
        </div>

        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["repeat(3, 260px)", "repeat(3, 1fr)"],
            gap: 5,
            gridColumn: [null, null, "span 8"],
            mx: [-4, 0],
            px: [4, 0],
            overflowX: "auto",
            scrollSnapType: "x mandatory",
          }}
        >
          {items.map((item, index) => (
            <div key={index} sx={{ scrollSnapAlign: "center" }}>
              <GatsbyImage
                image={item.image.imageFile.childImageSharp.gatsbyImageData}
                alt=""
                sx={{
                  height: [335, 366],
                  mb: 5,
                }}
              />

              <Heading as="h3" mb={2}>
                {item.title}
              </Heading>

              <p sx={{ my: 0 }}>{item.description}</p>
            </div>
          ))}
        </div>

        <div sx={{ display: [null, "none"] }}>
          <Button
            isFullWidth
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: primaryCta.targetUrl,
              }).click()
            }}
          >
            {primaryCta.label}
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default Highlights

export const highlightsFragment = graphql`
  fragment HighlightsFragment on Strapi_ComponentBlocksHighlights {
    title
    description
    items {
      title
      description
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 260, quality: 50, layout: CONSTRAINED)
          }
        }
      }
    }
    primaryCta {
      label
      targetUrl
    }
  }
`
