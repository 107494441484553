/** @jsx jsx */
import { graphql } from "gatsby"

import { Container, Heading, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import SignatureImage from "./components/signature-image"

const SkinGuideIndex = ({
  title,
  bgColor: backgroundColor = "background",
  ctas,
}) => (
  <div
    sx={{
      bg: backgroundColor,
      py: [0, 8],
      position: "relative",
    }}
  >
    <Container>
      <Heading
        sx={{
          mb: [5, 6],
          textAlign: "center",
          fontSize: [32, 38],
          letterSpacing: "0.03em",
        }}
      >
        {title}
      </Heading>
    </Container>
    <div
      sx={{
        position: "relative",
        ":before, :after": {
          content: [null, `""`],
          width: (theme) =>
            `calc((100% - min(${theme.sizes.container}px, 100vw)) / 2 + ${theme.space[7]}px)`,
          height: "1px",
          position: "absolute",
        },
        ":before": {
          left: 0,
          boxShadow: "0 -1px 0px 0px",
        },
        ":after": {
          right: 0,
          boxShadow: "0 1px 0px 0px",
        },
      }}
    >
      <Container
        sx={{
          position: "relative",
          ":before, :after": {
            content: [null, `""`],
            width: `calc((100% - min(100%, 700px)) / 2)`,
            height: "1px",
            position: "absolute",
          },
          ":before": {
            left: 0,
            boxShadow: "0 -1px 0px 0px",
          },
          ":after": {
            right: 0,
            boxShadow: "0 1px 0px 0px",
          },
        }}
      >
        <div
          sx={{
            maxWidth: [null, 700],
            mx: "auto",
          }}
        >
          {ctas?.map(({ label, targetUrl }, index) => (
            <TextLink
              key={index}
              href={targetUrl}
              inheritColor
              sx={{
                display: "block",
                width: [null, 500],
                ml: [
                  null,
                  `calc(${index} * (100% - 500px) / ${ctas.length - 1})`,
                ],
                boxShadow: "0 -1px 0px 0px, 0 1px 0px 0px",
                mt: index === 0 ? null : "1px",
                mb: index === ctas.length - 1 ? "-1px" : null,
              }}
            >
              <div
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: [null, 24],
                  py: 3,
                  fontFamily: "monospace",
                  variant: "text.caps",
                  fontSize: 1,
                }}
              >
                {label}
                <span
                  sx={{
                    fontSize: 3,
                  }}
                >
                  {">>"}
                </span>
              </div>
            </TextLink>
          ))}
        </div>
      </Container>
    </div>
    <SignatureImage
      q1={{ width: 3, height: 4 }}
      q3={{ width: 5, height: 6 }}
      sx={{
        display: ["none", "grid"],
        position: "absolute",
        top: 0,
        right: 0,
      }}
    />
    <SignatureImage
      q2={{ width: 4, height: 3 }}
      q4={{ width: 7, height: 5 }}
      sx={{
        display: ["none", "grid"],
        position: "absolute",
        bottom: 0,
        left: 0,
      }}
    />
  </div>
)

export default SkinGuideIndex

export const skinGuideIndexFragment = graphql`
  fragment SkinGuideIndexFragment on Strapi_ComponentBlocksSkinGuideIndex {
    title
    bgColor: backgroundColor
    ctas {
      label
      targetUrl
    }
  }
`
