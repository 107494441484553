/** @jsx jsx */
import { graphql } from "gatsby"

import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

import ImageCompare from "./components/image-compare"

const ProgressCard = ({
  author,
  summary,
  category,
  months,
  images,
  showHelper,
}) => (
  <div
    sx={{
      p: 4,
      boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
    }}
  >
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "monospace",
        mb: 4,
        mx: 4,
      }}
    >
      <div>
        <Trans i18nKey="ProgressCard.months" values={{ months }} />
      </div>
      <div
        sx={{
          variant: "text.caps",
          border: "1px solid",
          borderRadius: 12,
          px: 2,
        }}
      >
        {category}
      </div>
    </div>

    {images.length > 1 && (
      <ImageCompare
        before={images[0]}
        after={images[images.length - 1]}
        showHelper={showHelper}
      />
    )}
    <div
      sx={{
        mt: 4,
        mx: 4,
      }}
    >
      <div
        sx={{
          variant: "text.caps",
          fontFamily: "monospace",
        }}
      >
        {author}
      </div>
      <div
        sx={{
          mt: 2,
          lineHeight: "1.3em",
        }}
      >
        {summary}
      </div>
    </div>
  </div>
)

const ProgressGrid = ({ reviews = [] }) => (
  <div
    sx={{
      display: "grid",
      gridTemplateColumns: ["auto", "repeat(3, 1fr)"],
      gridGap: 5,
      mb: 5,
    }}
  >
    {reviews.map(({ author, summary, category, months, images }, index) => (
      <ProgressCard
        key={index}
        months={months}
        author={author}
        summary={summary}
        category={category}
        images={images}
        showHelper={index === 0}
      />
    ))}
  </div>
)

const ProgressBoard = ({ title, reviews }) => (
  <div>
    <Container>
      <Heading
        size="lg"
        sx={{
          mb: [5, 6],
          textAlign: "center",
        }}
      >
        {title}
      </Heading>
      <ProgressGrid reviews={reviews} />
    </Container>
  </div>
)

export default ProgressBoard

export const progressBoardFragment = graphql`
  fragment ProgressBoardFragment on Strapi_ComponentBlocksProgressBoard {
    title
    reviews {
      author
      summary
      category
      months: monthsInTreatment
      images {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 350, height: 350, layout: CONSTRAINED)
          }
        }
        timestamp: createdAt
      }
    }
  }
`
