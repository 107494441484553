/** @jsx jsx */
import { graphql } from "gatsby"

import { jsx } from "@trueskin-web/theme"

import Seo from "../../components/seo"

const isProduction = process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"

const SEODetails = ({
  title,
  canonicalUrl,
  metaTitle,
  metaDescription,
  robotsIndex,
  robotsFollow,
  redirectUrl,
  alternateLinks,
}) => {
  const meta = []
  if (!isProduction) {
    robotsIndex = false
    robotsFollow = true
  }
  if (robotsIndex !== undefined && robotsFollow !== undefined) {
    meta.push({
      name: `robots`,
      content: `${robotsIndex ? "index" : "noindex"},${
        robotsFollow ? "follow" : "nofollow"
      }`,
    })
  }

  if (redirectUrl) {
    meta.push({
      "http-equiv": `Refresh`,
      content: `0; URL=${redirectUrl}`,
    })
  }

  return (
    <Seo
      title={metaTitle ? metaTitle : title}
      description={metaDescription}
      meta={meta}
    >
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {alternateLinks?.map((link, index) => (
        <link
          rel="alternate"
          hreflang={link.hreflang}
          href={link.href}
          key={index}
        />
      ))}
    </Seo>
  )
}

export default SEODetails

export const seoDetailsFragment = graphql`
  fragment SEODetailsFragment on Strapi_ComponentBlogSeoContent {
    canonicalUrl
    metaTitle
    metaDescription
    robotsIndex
    robotsFollow
    redirectUrl
    alternateLinks {
      hreflang
      href
    }
  }
`
