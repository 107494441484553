/** @jsx jsx */
import { graphql } from "gatsby"

import { Container } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const Space = ({ desktopSpace, mobileSpace, fullWidth, backgroundColor }) => {
  const { value: lg } = desktopSpace || {}
  const { value: sm } = mobileSpace || {}

  if (!sm && !lg) {
    return null
  }

  return (
    <div>
      {sm ? (
        <div
          sx={{
            pt: sm,
            backgroundColor,
            display: ["block", "none"],
          }}
        ></div>
      ) : null}
      {lg ? (
        fullWidth ? (
          <div
            sx={{
              pt: lg,
              backgroundColor,
              display: ["none", "block"],
            }}
          ></div>
        ) : (
          <Container
            sx={{
              display: ["none", "block"],
            }}
          >
            <div
              sx={{
                pt: lg,
                backgroundColor,
              }}
            ></div>
          </Container>
        )
      ) : null}
    </div>
  )
}

export default Space

export const spaceFragment = graphql`
  fragment SpaceFragment on Strapi_ComponentAtomsSpace {
    fullWidth
    backgroundColor
    desktopSpace {
      value
    }
    mobileSpace {
      value
    }
  }
`
