/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const ContentImage = ({ title, description, image }) => (
  <div sx={{ variant: "layout.sectionPadding", bg: "grey" }}>
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", "2fr 1fr"],
        alignItems: "flex-start",
        gap: 7,
      }}
    >
      <GatsbyImage
        image={image.imageFile.childImageSharp.gatsbyImageData}
        alt=""
      />

      <div>
        <Heading size="lg" mb={[4, 5]}>
          {title}
        </Heading>

        <div>{description}</div>
      </div>
    </Container>
  </div>
)

export default ContentImage

export const contentImageFragment = graphql`
  fragment ContentImageFragment on Strapi_ComponentBlocksContentImage {
    title
    description
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED)
        }
      }
    }
  }
`
