/** @jsx jsx */
import { graphql } from "gatsby"

import {
  Button,
  Container,
  Heading,
  UserGeneratedHtml,
  YoutubeEmbed,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const ContentVideo = ({
  videoTitle,
  videoContent,
  youtubeUrl,
  primaryCta,
  secondaryCta,
}) => (
  <div
    sx={{
      py: 7,
    }}
  >
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", youtubeUrl ? "repeat(2, 1fr)" : "auto"],
        alignItems: "flex-start",
        gap: [5, 0],
      }}
    >
      <Heading
        size="lg"
        sx={{
          display: [null, "none"],
          textAlign: "center",
        }}
      >
        {videoTitle}
      </Heading>

      {youtubeUrl && (
        <div
          sx={{
            alignSelf: "center",
            order: [null, 1],
          }}
        >
          <YoutubeEmbed url={youtubeUrl} />
        </div>
      )}

      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            width: [undefined, "min-content"],
            mr: [undefined, youtubeUrl ? 6 : undefined],
          }}
        >
          <Heading
            size="lg"
            mb={[4, 5]}
            sx={{ display: ["none", "inline-block"] }}
          >
            {videoTitle}
          </Heading>

          <UserGeneratedHtml html={videoContent} sx={{ mb: 6 }} />

          <div
            sx={{
              display: ["flex", "inline-flex"],
              flexDirection: "column",
              gap: 3,
            }}
          >
            {primaryCta && (
              <Button
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: primaryCta.targetUrl,
                  }).click()
                }}
                sx={{
                  width: [undefined, 360],
                  maxWidth: "100%",
                }}
              >
                {primaryCta.label}
              </Button>
            )}
            {secondaryCta && (
              <Button
                variant="outline"
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: secondaryCta.targetUrl,
                  }).click()
                }}
                sx={{
                  width: [undefined, 360],
                  maxWidth: "100%",
                }}
              >
                {secondaryCta.label}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  </div>
)

export default ContentVideo

export const contentVideoFragment = graphql`
  fragment ContentVideoFragment on Strapi_ComponentBlocksContentVideo {
    videoTitle
    videoContent
    youtubeUrl
    primaryCta {
      label
      targetUrl
    }
    secondaryCta {
      label
      targetUrl
    }
  }
`
