/** @jsx jsx */
import { FORM_ERROR } from "final-form"
import { graphql } from "gatsby"
import { Fragment, useState } from "react"
import { Field, Form } from "react-final-form"

import { customerIoClient } from "@trueskin-web/apis"
import {
  Button,
  CheckboxControl,
  FieldError,
  Heading,
  Icon,
  InputControl,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import {
  composeValidators,
  getErrorMessage,
  mustBeEmail,
  mustBeTrue,
  required,
} from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"
import { emailIcon } from "@trueskin-web/theme/icons/emailIcon"
import { Trans } from "@trueskin-web/translations"

const NewsletterSignup = ({
  title,
  description,
  showFirstname,
  firstnameLabel,
  emailLabel,
  termsAndConditions,
  marketingIdentifier,
  ctaLabel,
}) => {
  const [signupConfirmed, setSignupConfirmed] = useState(false)

  const onSubmit = async (values) => {
    try {
      await customerIoClient.subscribe(values)
      await customerIoClient.track({
        customerId: values.email,
        source: marketingIdentifier,
      })
      setSignupConfirmed(true)
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) }
    }
  }

  return (
    <div
      sx={{
        py: 7,
        px: 4,
        bg: "blue",
      }}
    >
      <div sx={{ mx: "auto", maxWidth: ["auto", 850] }}>
        {signupConfirmed ? (
          <div sx={{ textAlign: "center" }}>
            <Heading size="lg" sx={{ mb: 5 }}>
              <Trans i18nKey="NewsletterSignup.successTitle" />
            </Heading>
            <div>
              &#128154;{" "}
              <Trans i18nKey="NewsletterSignup.successMessage">
                <span sx={{ fontWeight: "bold" }} />
              </Trans>
            </div>
          </div>
        ) : (
          <Fragment>
            <Heading size="lg" sx={{ textAlign: "center" }}>
              {title}
            </Heading>
            <UserGeneratedHtml
              data-hj-suppress
              data-clarity-mask="True"
              html={description}
            />
            <Form
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                submitting,
                dirty,
                dirtySinceLastSubmit,
                submitError,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 3,
                      "& > *": {
                        mb: 5,
                        flex: 1,
                        flexBasis: ["100%", "auto"],
                      },
                    }}
                  >
                    {showFirstname && (
                      <Field
                        name="first_name"
                        component={InputControl}
                        label={firstnameLabel || "Name"}
                      />
                    )}
                    <Field
                      name="email"
                      component={InputControl}
                      validate={composeValidators(required, mustBeEmail)}
                      type="email"
                      label={emailLabel}
                    />
                  </div>
                  <Field
                    name="termsAndConditions"
                    component={CheckboxControl}
                    type="checkbox"
                    validate={mustBeTrue}
                  >
                    <UserGeneratedHtml
                      data-hj-suppress
                      data-clarity-mask="True"
                      html={termsAndConditions}
                    />
                  </Field>

                  {submitError && (
                    <FieldError sx={{ mt: 5 }}>{submitError}</FieldError>
                  )}

                  <Button
                    type="submit"
                    size="lg"
                    isLoading={submitting}
                    isDisabled={!dirty && !dirtySinceLastSubmit}
                    sx={{
                      mt: 5,
                      gap: 3,
                      width: ["100%", "auto"],
                    }}
                  >
                    <Icon icon={emailIcon} size="sm" />
                    {ctaLabel}
                  </Button>
                </form>
              )}
            />
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default NewsletterSignup

export const newsletterSignupFragment = graphql`
  fragment NewsletterSignupFragment on Strapi_ComponentBlocksNewsletterSignup {
    title
    description
    showFirstname
    firstnameLabel
    emailLabel
    termsAndConditions
    marketingIdentifier
    ctaLabel
  }
`
