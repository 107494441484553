/** @jsx jsx */
import { useState } from "react"

import { Heading, IconButton } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { minusIcon } from "@trueskin-web/theme/icons/minusIcon"
import { plusIcon } from "@trueskin-web/theme/icons/plusIcon"
import { Trans } from "@trueskin-web/translations"

const VerticalStep = ({ index, step, expanded }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div
      sx={{
        position: "relative",
        ml: ["6px", "13px"],
        pl: ["18px", "30px"],
        borderLeft: "1px",
        "&:last-of-type": {
          borderColor: "transparent",
        },
        "&:not(:last-of-type)": {
          pb: [6, 7],
        },
        "&:after": {
          position: "absolute",
          top: [0, "-4px"],
          left: ["-7px", "-13px"],
          display: "block",
          width: [11, 22],
          height: [11, 22],
          content: "' '",
          backgroundColor: isExpanded ? "text" : "lightYellow",
          border: "1px",
        },
      }}
    >
      <div
        sx={{
          position: "relative",
          top: "-3px",
          variant: "text.caps",
          fontSize: [1, 2],
          mb: 1,
        }}
      >
        <Trans i18nKey="VerticalStep.step" values={{ value: index }} />
      </div>

      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Heading as="h4" size="sm" sx={{ fontSize: [null, 5] }}>
          {step.title}
        </Heading>

        {!expanded && (
          <IconButton
            icon={isExpanded ? minusIcon : plusIcon}
            size="sm"
            sx={{ display: [null, "none"] }}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        )}
      </div>

      <div
        sx={{
          display: expanded
            ? "block"
            : [isExpanded ? "block" : "none", "block"],
          mt: 2,
          fontSize: [null, 3],
        }}
      >
        {step.description}
      </div>
    </div>
  )
}

export default VerticalStep
