/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment, forwardRef, memo, useEffect, useRef, useState } from "react"

import { Container, Heading, Tappable } from "@trueskin-web/components"
import { conditionalFunction } from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"

const Doctors = memo(
  forwardRef(({ doctors }, ref) => (
    <Fragment>
      {doctors.map(({ title, doctor }, index) => (
        <div key={index}>
          <div ref={ref}>
            <GatsbyImage
              image={doctor.image.imageFile.childImageSharp.gatsbyImageData}
              alt=""
            />
          </div>
          <div
            sx={{
              fontFamily: "monospace",
              fontSize: 0,
            }}
          >
            {title}
          </div>
        </div>
      ))}
    </Fragment>
  ))
)

const MedicalGrid = ({ doctors = [], cta, ctaBackgroundColor }) => {
  const imageRef = useRef(null)
  const [imageSize, setImageSize] = useState()

  useEffect(() => {
    const handleResize = () => {
      conditionalFunction({
        condition: () => imageRef.current,
        success: () => {
          setImageSize({
            width: imageRef.current.offsetWidth,
            height: imageRef.current.offsetHeight,
          })
        },
      })
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [imageRef])

  return (
    <div
      sx={{
        position: "relative",
        display: ["grid", "inline-grid"],
        gridGap: ["12px 16px", "16px 24px"],
        gridTemplateColumns: ["repeat(2, 1fr)", "repeat(3, 1fr)"],
        mr: [null, 5],
      }}
    >
      <Doctors doctors={doctors} ref={imageRef} />
      <Tappable
        onClick={() => {
          Object.assign(document.createElement("a"), {
            href: cta.targetUrl,
          }).click()
        }}
        sx={{
          position: "relative",
          display: "inline-grid",
          backgroundColor: ctaBackgroundColor,
          gridColumnStart: [(doctors.length % 2) + 1, (doctors.length % 3) + 1],
          gridColumnEnd: [3, 4],
          height: imageSize?.height,
          transition: "opacity 0.5s",
          opacity: imageSize ? 1 : 0,
        }}
      >
        <div
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            px: 4,
            py: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            whiteSpace: "normal",
            textAlign: "left",
          }}
        >
          {cta.label}
        </div>
        <div
          sx={{
            position: "absolute",
            right: 4,
            bottom: 5,
            left: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 20,
            borderLeft: "1px",
            borderBottom: "1px",
            ":after": {
              content: `">>"`,
              position: "absolute",
              right: 0,
              bottom: "-0.8em",
              paddingLeft: 4,
              fontSize: 18,
              letterSpacing: "0.08em",
              backgroundColor: ctaBackgroundColor,
            },
          }}
        />
      </Tappable>
    </div>
  )
}

const MedicalIntro = ({
  title,
  description,
  cta,
  ctaBackgroundColor = "#FBDCDC",
  doctors,
  bgColor: backgroundColor = "#FBF1F0",
}) => (
  <div
    sx={{
      bg: backgroundColor,
      py: [6, 8],
    }}
  >
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "3fr 2fr"],
        }}
      >
        <MedicalGrid
          doctors={doctors}
          cta={cta}
          ctaBackgroundColor={ctaBackgroundColor}
        />
        <div
          sx={{
            flex: 1,
            order: [-1, 1],
            maxWidth: 450,
            alignSelf: [null, "center"],
            marginLeft: [null, "auto"],
            marginRight: [null, "auto"],
            letterSpacing: "0.03em",
          }}
        >
          <Heading
            sx={{
              textAlign: ["center", "left"],
              fontSize: [32, 38],
            }}
          >
            {title}
          </Heading>
          {description && (
            <div
              sx={{
                whiteSpace: "pre-wrap",
                mt: [5, 6],
                mb: [5, 0],
              }}
            >
              {description}
            </div>
          )}
        </div>
      </div>
    </Container>
  </div>
)

export default MedicalIntro

export const medicalIntroFragment = graphql`
  fragment MedicalIntroFragment on Strapi_ComponentBlocksMedicalIntro {
    title
    description
    bgColor: backgroundColor
    cta {
      label
      targetUrl
    }
    ctaBackgroundColor
    doctors {
      title
      doctor {
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 400, aspectRatio: 1, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
