/** @jsx jsx */
import { graphql } from "gatsby"

import {
  Button,
  Container,
  HStack,
  Heading,
  Link,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import { ListItem } from "./list"

const ContentCtaList = ({
  title,
  content,
  details,
  primaryCta,
  secondaryCta,
}) => (
  <div sx={{ mt: [9, 8], mb: [9, 10] }}>
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
        gap: [7, 5],
      }}
    >
      <div sx={{ gridColumn: [null, "6 / span 7"], order: [null, 1] }}>
        <Heading size="lg" mb={[3, 4]}>
          {title}
        </Heading>

        <UserGeneratedHtml html={content} sx={{ mb: [null, 7] }} />

        <HStack sx={{ display: ["none", "flex"] }}>
          {secondaryCta && (
            <Button variant="outline" as={Link} href={secondaryCta.targetUrl}>
              {secondaryCta.label}
            </Button>
          )}
          <Button as={Link} href={primaryCta.targetUrl} rel="noindex">
            {primaryCta.label}
          </Button>
        </HStack>
      </div>

      <div sx={{ gridColumn: [null, "span 4"] }}>
        <div sx={{ display: "grid", gap: [5, 6], mb: [7, 0] }}>
          {details.map((item) => (
            <ListItem
              key={item.title}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
            />
          ))}
        </div>
        <div
          sx={{
            display: ["grid", "none"],
            gap: 4,
          }}
        >
          <Button as={Link} href={primaryCta.targetUrl} rel="noindex">
            {primaryCta.label}
          </Button>
          {secondaryCta && (
            <Button variant="outline" as={Link} href={secondaryCta.targetUrl}>
              {secondaryCta.label}
            </Button>
          )}
        </div>
      </div>
    </Container>
  </div>
)

export default ContentCtaList

export const contentCtaListFragment = graphql`
  fragment ContentCtaListFragment on Strapi_ComponentLandingContentCtaList {
    title
    content
    primaryCta {
      label
      targetUrl
    }
    secondaryCta {
      label
      targetUrl
    }
    details {
      title
      subtitle
      description
    }
  }
`
