/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { Fragment, useState } from "react"

import { CdnImage } from "@trueskin-web/components"
import {
  Button,
  Container,
  Dialog,
  GoogleRating,
  Heading,
  Icon,
  TrustpilotRating,
  YoutubeEmbed,
} from "@trueskin-web/components"
import { formatCurrency, formatCurrencySymbol } from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"
import { playIcon } from "@trueskin-web/theme/icons/playIcon"

import SignatureImage from "./components/signature-image"
import Typewriter from "./components/typewriter"
import { TRUSTPILOT_RATING } from "./utils/constants"

const HeroPriceTrustpilot = ({
  image,
  smallImage,
  heroTrustTitle: title,
  description,
  htCta: cta,
  htCtaDescription: ctaDescription,
  secondaryCta,
  isSecondaryCtaYoutube,
  heroTrustPrice: price,
  heroPriceCurrency: priceCurrency,
  priceColor,
  priceBackgroundColor,
  heroTrustPriceTitle: priceTitle,
  heroTrustPriceSubtitle: priceSubtitle,
  altDescription,
  trustpilotDescription,
  backgroundColor,
  enableRating,
  aliasRatingService: ratingService,
  isImageFullWidth,
}) => {
  const [dialogYoutube, setDialogYoutube] = useState(false)

  // const images = useMemo(
  //   () =>
  //     withArtDirection(getImage(image.imageFile), [
  //       {
  //         media: "(max-width: 54em)",
  //         image: getImage(smallImage.imageFile),
  //       },
  //     ]),
  //   [image, smallImage]
  // )

  const {
    google: {
      result: { rating: googleRating },
    },
    trustpilot: {
      score: { trustScore: trustpilotRating = TRUSTPILOT_RATING },
    },
  } = useStaticQuery(
    graphql`
      {
        google {
          result {
            rating
          }
        }
        trustpilot {
          score {
            trustScore
          }
        }
      }
    `
  )

  return (
    <div
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor,
      }}
    >
      {/* <GatsbyImage
        image={images}
        alt=""
        objectFit="contain"
        objectPosition="0 50%"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: ["50%", "55%"],
          maxHeight: "100%",
        }}
      /> */}
      <div
        sx={{
          display: [undefined, "none"],
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: isImageFullWidth ? 0 : "55%",
        }}
      >
        <CdnImage
          src={getSrc(smallImage.imageFile)}
          sx={{
            height: "100%",
            width: isImageFullWidth ? "100%" : undefined,
            objectFit: "cover",
          }}
        />
      </div>

      <Container>
        <div
          sx={{
            position: "relative",
            pt: [0, 9],
            pb: [0, 7],
            zIndex: 1,
          }}
        >
          <div
            sx={{
              display: ["none", "block"],
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: isImageFullWidth ? 0 : "55%",
              zIndex: -1,
            }}
          >
            <CdnImage
              src={getSrc(image.imageFile)}
              sx={{
                height: "100%",
                width: isImageFullWidth ? "100%" : undefined,
                objectFit: "cover",
              }}
            />
          </div>
          <div
            sx={{
              position: "relative",
            }}
          >
            <Heading
              as="h1"
              sx={{
                width: ["75%", "60%"],
                fontSize: [6, 7],
                letterSpacing: "0.03em",
              }}
            >
              <Typewriter text={title} />
            </Heading>
            <SignatureImage
              q1={{ width: 4, height: 3 }}
              q3={{ width: 2, height: 3 }}
              sx={{
                display: ["none", "grid"],
                position: "absolute",
                top: -2,
                left: -3,
                transform: "translate(-33%, -50%)",
              }}
            />
          </div>
          {trustpilotDescription && enableRating && (
            <Fragment>
              {ratingService === "trustpilot" && (
                <TrustpilotRating
                  rating={trustpilotRating}
                  sx={{
                    mt: [6, 40],
                  }}
                />
              )}
              {ratingService === "google" && (
                <GoogleRating
                  rating={googleRating}
                  sx={{
                    mt: [6, 40],
                  }}
                />
              )}

              <div
                dangerouslySetInnerHTML={{ __html: trustpilotDescription }}
                sx={{
                  width: "min(75%, 310px)",
                  fontSize: 0,
                  "*": {
                    p: 0,
                    m: 0,
                  },
                }}
              />
            </Fragment>
          )}
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              sx={{
                width: [undefined, "50%"],
                mt: [4, 7],
                fontSize: [2, 4],
                "p, ul, ol": {
                  my: 0,
                },
                ul: {
                  listStyle: "none",
                  pl: 0,
                },
                "ul > li:before": {
                  content: `"•"`,
                  fontSize: 2,
                  pr: 2,
                },
              }}
            />
            {price && (
              <div
                sx={{
                  display: ["inline-flex", "none"],
                  flexDirection: "column",
                  textAlign: "center",
                  px: 2,
                  ml: description ? 4 : undefined,
                  color: priceColor,
                }}
              >
                <div
                  sx={{
                    fontSize:
                      formatCurrencySymbol(priceCurrency).length > 1 ? 3 : 5,
                    position: "relative",
                    zIndex: 1,
                    ":before": {
                      content: `""`,
                      position: "absolute",
                      zIndex: -1,
                      width: "125%",
                      paddingTop: "125%",
                      borderRadius: "50%",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: priceBackgroundColor,
                    },
                  }}
                >
                  {formatCurrency(price, priceCurrency)}
                </div>
              </div>
            )}
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row-reverse"],
              alignItems: [null, "center"],
              justifyContent: [null, "space-between"],
              mt: [0, 8],
            }}
          >
            <div
              sx={{
                my: [0, 6],
                display: "flex",
                width: ["100%", "40%"],
                flexDirection: ["row", "row-reverse"],
                flexWrap: ["wrap", "nowrap"],
                alignItems: "center",
              }}
            >
              {!trustpilotDescription && enableRating && (
                <Fragment>
                  {ratingService === "trustpilot" && (
                    <TrustpilotRating
                      rating={trustpilotRating}
                      compact={true}
                    />
                  )}
                  {ratingService === "google" && (
                    <GoogleRating rating={googleRating} compact={true} />
                  )}
                </Fragment>
              )}
              {price && (
                <div
                  sx={{
                    display: ["none", "inline-flex"],
                    flexDirection: "column",
                    textAlign: "center",
                    px: 4,
                    ml: trustpilotDescription ? [0, 4] : 4,
                    mr: !trustpilotDescription && [0, 4],
                    color: priceColor,
                  }}
                >
                  <div
                    sx={{
                      fontSize:
                        formatCurrencySymbol(priceCurrency).length > 1 ? 5 : 6,
                      position: "relative",
                      zIndex: 1,
                      ":before": {
                        content: `""`,
                        position: "absolute",
                        zIndex: -1,
                        width: "125%",
                        paddingTop: "125%",
                        borderRadius: "50%",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: priceBackgroundColor,
                      },
                    }}
                  >
                    <div
                      sx={{
                        px: 3,
                      }}
                    >
                      {formatCurrency(price, priceCurrency)}
                    </div>
                    <div
                      sx={{
                        fontFamily: "monospace",
                        fontSize: 0,
                      }}
                    >
                      <div
                        sx={{
                          fontSize: 1,
                        }}
                      >
                        {priceTitle}
                      </div>
                      <div
                        sx={{
                          fontSize: 0,
                        }}
                      >
                        {priceSubtitle}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {altDescription && (
                <div
                  dangerouslySetInnerHTML={{ __html: altDescription }}
                  sx={{
                    width: ["100%", "auto"],
                    fontFamily: "monospace",
                    fontSize: [0, 1],
                    mt: [4, 0],
                    "p, ul, ol": {
                      my: 0,
                    },
                    ul: {
                      listStyle: "none",
                      pl: 0,
                      ml: 0,
                      "& > li": {
                        position: "relative",
                        mt: [2, 1],
                        pl: [5, 5],
                        "&:before": {
                          content: `""`,
                          position: "absolute",
                          top: [0, "4px"],
                          left: [0, "4px"],
                          p: [2, "6px"],
                          borderRadius: "50%",
                          border: "1px solid",
                          backgroundColor: "white",
                        },
                        "&:after": {
                          content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' focusable='false' role='presentation'%3E%3Cpath d='M8.333 2.5L3.75 7.083 1.667 5' stroke='%23000000' stroke-width='1' stroke-linecap='square' fill='none'%3E%3C/path%3E%3C/svg%3E")`,
                          display: "inline-block",
                          position: "absolute",
                          top: ["2px", "1px"],
                          left: ["2px", "6px"],
                          width: ["14px", "10px"],
                          height: ["14px", "10px"],
                          lineHeight: 1,
                          textAlign: "center",
                          fontSize: [1, 3],
                          fontFamily: "monospace",
                          fontWeight: 600,
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
            <div
              sx={{
                display: "flex",
                width: ["100%", "60%"],
                flexDirection: "column",
                mt: [4, 7],
              }}
            >
              <div
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
                  gridGap: 4,
                  order: secondaryCta && [1, 0],
                }}
              >
                <Button
                  onClick={() => {
                    Object.assign(document.createElement("a"), {
                      href: cta.targetUrl,
                    }).click()
                  }}
                  sx={{
                    width: ["100%", "auto"],
                    maxWidth: "100%",
                    lineHeight: "16px",
                  }}
                >
                  {cta.label}
                </Button>

                {secondaryCta && (
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (isSecondaryCtaYoutube) {
                        setDialogYoutube(true)
                      } else {
                        Object.assign(document.createElement("a"), {
                          target: "_blank",
                          href: secondaryCta.targetUrl,
                        }).click()
                      }
                    }}
                  >
                    {isSecondaryCtaYoutube && (
                      <Icon
                        icon={playIcon}
                        size="sm"
                        sx={{
                          mr: 2,
                        }}
                      />
                    )}
                    {secondaryCta.label}
                  </Button>
                )}
              </div>
              {ctaDescription && (
                <div
                  sx={{
                    my: [1, 2],
                    fontSize: 1,
                    textAlign: "center",
                    width: ["100%", "calc(50% - 8px)"],
                  }}
                >
                  {ctaDescription}
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
      {dialogYoutube && (
        <Dialog
          aria-label={secondaryCta.label}
          onDismiss={() => setDialogYoutube(false)}
          sx={{
            maxWidth: "calc((100vh - 90px) * 16 / 9)",
          }}
        >
          <YoutubeEmbed url={secondaryCta.targetUrl} autoplay={true} />
        </Dialog>
      )}
    </div>
  )
}

export default HeroPriceTrustpilot

export const heroPriceTrustpilotFragment = graphql`
  fragment HeroPriceTrustpilotFragment on Strapi_ComponentBlocksHeroPriceTrustpilot {
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 2400, layout: CONSTRAINED)
        }
      }
    }
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    heroTrustTitle: title
    description
    htCta: cta {
      label
      targetUrl
    }
    secondaryCta {
      label
      targetUrl
    }
    isSecondaryCtaYoutube
    htCtaDescription: ctaDescription
    heroTrustPrice: price
    heroPriceCurrency: priceCurrency
    priceColor
    priceBackgroundColor
    heroTrustPriceTitle: priceTitle
    heroTrustPriceSubtitle: priceSubtitle
    altDescription
    trustpilotDescription
    backgroundColor
    enableRating
    aliasRatingService: ratingService
    isImageFullWidth
  }
`
