/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useEffect, useState } from "react"

import {
  Button,
  Container,
  Heading,
  Link,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const headlineParam = "hd"
const subheadlineParam = "sub_hd"

const Hero = ({
  title,
  description,
  richDescription,
  smallImage,
  image,
  primaryCta,
  secondaryCta,
  onHomepage,
}) => (
  <div sx={{ mt: [5, 7], mb: [9, 10] }}>
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
          gap: 5,
          position: "relative",
          minHeight: [null, 544],
        }}
      >
        <GatsbyImage
          image={smallImage.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            display: [null, "none"],
            maxHeight: 400,
          }}
        />

        <GatsbyImage
          image={image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            display: ["none", "block"],
            position: "absolute !important",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
          }}
        />

        <div
          sx={{
            display: [null, "flex"],
            flexDirection: [null, "column"],
            justifyContent: [null, "center"],
            gridColumn: [null, "2 / span 6", "2 / span 5"],
            maxWidth: [null, 460],
            py: [null, 7],
          }}
        >
          <Heading as="h1" size="xl" mb={[2, 5]}>
            {title}
          </Heading>

          {richDescription ? (
            <UserGeneratedHtml
              html={richDescription}
              sx={{ mt: 0, mb: [5, 7], fontSize: 3 }}
            />
          ) : (
            <p sx={{ mt: 0, mb: [5, 7], fontSize: 3 }}>{description}</p>
          )}

          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              "& > *:first-of-type:not(:last-of-type)": {
                mb: [4, 0],
                ml: [null, 5],
                order: [null, 1],
              },
            }}
          >
            <Button
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: primaryCta.targetUrl,
                }).click()
              }}
              {...(onHomepage && {
                id: "hero-cta",
              })}
            >
              {primaryCta.label}
            </Button>

            {secondaryCta && (
              <Button
                variant="outline"
                as={Link}
                href={secondaryCta.targetUrl}
                {...(onHomepage && {
                  id: "hero-secondary-cta",
                })}
                sx={{
                  ml: [null, 5],
                  mt: [4, 0],
                }}
              >
                {secondaryCta.label}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  </div>
)

const HeroFragment = ({
  blockIsFirst,
  title: heroTitle,
  richDescription: heroDescription,
  ...props
}) => {
  const location = useLocation()
  const [title, setTitle] = useState(heroTitle)
  const [richDescription, setRichDescription] = useState(heroDescription)

  useEffect(() => {
    if (!blockIsFirst) {
      return
    }

    const searchParams = new URLSearchParams(location.search)
    const headline = searchParams.get(headlineParam)
    const subheadline = searchParams.get(subheadlineParam)

    if (headline) {
      setTitle(headline)
    }

    if (subheadline) {
      setRichDescription(subheadline)
    }
  }, [blockIsFirst, location.search])

  return <Hero title={title} richDescription={richDescription} {...props} />
}

export default HeroFragment

export const heroFragment = graphql`
  fragment HeroFragment on Strapi_ComponentBlocksHero {
    title
    description
    richDescription
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 800, aspectRatio: 1.27, layout: CONSTRAINED)
        }
      }
    }
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 2.05, layout: FULL_WIDTH)
        }
      }
    }
    primaryCta {
      label
      targetUrl
    }
    secondaryCta {
      label
      targetUrl
    }
  }
`
