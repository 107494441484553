/** @jsx jsx */
import { graphql } from "gatsby"

import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

export const ListItem = ({ title, subtitle, description }) => (
  <div sx={{ pb: 4, borderBottom: "1px" }}>
    <div
      sx={{
        variant: "text.caps",
        mb: [2, 1],
        fontSize: [0, 1],
        fontFamily: "monospace",
      }}
    >
      {subtitle}
    </div>
    <Heading as="h3" mb={4} sx={{ fontSize: [4, 5] }}>
      {title}
    </Heading>
    <p sx={{ my: 0 }}>{description}</p>
  </div>
)

const List = ({ items }) => (
  <div
    sx={{
      mt: [7, 0],
      mb: [9, 0],
      pt: [null, 8],
      pb: [null, 7],
      bg: [null, "lightGray"],
    }}
  >
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(3, 1fr)"],
          gap: 5,
        }}
      >
        {items.map((item) => (
          <ListItem
            key={item.title}
            title={item.title}
            subtitle={item.subtitle}
            description={item.description}
          />
        ))}
      </div>
    </Container>
  </div>
)

export default List

export const listFragment = graphql`
  fragment ListFragment on Strapi_ComponentLandingList {
    items {
      title
      subtitle
      description
    }
  }
`
