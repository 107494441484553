/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment, useEffect, useState } from "react"

import { Container, Heading, Icon, Tappable } from "@trueskin-web/components"
import { jsx, useBreakpointIndex } from "@trueskin-web/theme"
import { creamJarIcon } from "@trueskin-web/theme/icons/creamJarIcon"
import { faceZenIcon } from "@trueskin-web/theme/icons/faceZenIcon"
import { waterDropIcon } from "@trueskin-web/theme/icons/waterDropIcon"
import { Trans, useTranslation } from "@trueskin-web/translations"

const BenefitIcon = ({ name = "", image }) => {
  if (image) {
    return (
      <div
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: 40,
          height: 40,
        }}
      >
        <img
          src={image.url}
          alt=""
          sx={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </div>
    )
  }

  let icon = null
  switch (name.toLowerCase()) {
    case "calming":
      icon = faceZenIcon
      break
    case "beruhigend":
      icon = creamJarIcon
      break
    case "feuchtigkeitsspendend":
      icon = waterDropIcon
      break
    default:
      break
  }

  if (icon) {
    return <Icon icon={icon} size="xlg" />
  }

  return null
}

const ProductButton = ({ title, subtitle, price, isLeftArrow, ...props }) => (
  <Tappable
    sx={{
      display: "inline-flex",
      px: 6,
      py: 5,
      width: ["100%", 350],
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "monospace",
      fontSize: 1,
      textTransform: "uppercase",
      letterSpacing: "0.2em",
      whiteSpace: "normal",
      textAlign: "left",
      boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
    }}
    {...props}
  >
    <div
      sx={{
        fontFamily: "monospace",
        fontSize: 10,
        letterSpacing: "0.15em",
      }}
    >
      <div
        sx={{
          fontSize: 2,
          letterSpacing: "0.03em",
        }}
      >
        {title}
      </div>
      {subtitle}
      {price && (
        <span
          sx={{
            pl: 1,
            ":before": {
              content: `"•"`,
              fontWeight: "bold",
              pr: 1,
            },
          }}
        >
          {price}
        </span>
      )}
    </div>
    <div
      sx={{
        order: isLeftArrow ? -1 : undefined,
        ml: isLeftArrow ? undefined : 6,
        mr: isLeftArrow ? 6 : undefined,
        fontFamily: "body",
        fontSize: 3,
        letterSpacing: "0.08em",
        whiteSpace: "nowrap",
      }}
    >
      {isLeftArrow ? "<<" : ">>"}
    </div>
  </Tappable>
)

const ProductImage = ({ image, imageDescription, imageBackgroundColor }) => (
  <div
    sx={{
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 240,
      pt: imageBackgroundColor ? 3 : undefined,
      pb: imageBackgroundColor ? 8 : undefined,
      background: (theme) =>
        `radial-gradient(circle, ${theme.colors.background} 0%, ${theme.colors.lightGray} 50%)`,
      ":before": {
        content: imageBackgroundColor ? `""` : undefined,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: "53%",
        left: 0,
        background: `linear-gradient(150deg, transparent 0%, ${imageBackgroundColor} 50%)`,
      },
      ":after": {
        content: `"${imageDescription}"`,
        position: "absolute",
        right: 4,
        bottom: 4,
        px: "10px",
        py: "2px",
        fontFamily: "monospace",
        fontSize: 0,
        color: "text",
        textTransform: "uppercase",
        letterSpacing: "0.15em",
        border: "1px solid",
        borderRadius: 12,
      },
    }}
  >
    <GatsbyImage
      image={image.imageFile.childImageSharp.gatsbyImageData}
      alt=""
      objectFit={imageBackgroundColor ? "contain" : undefined}
      sx={{
        height: "100%",
      }}
    />
  </div>
)

const ProductBenefits = ({ items, isSelected }) => (
  <div
    sx={{
      display: [isSelected ? "flex" : "none", "flex"],
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    }}
  >
    {items.map(({ image, alternateText }, index) => (
      <div
        key={index}
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 5,
          mr: index === items.length - 1 ? 0 : 4,
        }}
      >
        <BenefitIcon name={alternateText} image={image} />
        <div
          sx={{
            variant: "text.caps",
            fontSize: 10,
            mt: 2,
          }}
        >
          {alternateText}
        </div>
      </div>
    ))}
  </div>
)

const ProductDetails = ({
  description,
  benefits,
  image,
  imageDescription,
  imageBackgroundColor,
  isSelected,
}) => (
  <div>
    <ProductImage
      image={image}
      imageDescription={imageDescription}
      imageBackgroundColor={imageBackgroundColor}
    />
    <ProductBenefits items={benefits} isSelected={isSelected} />
    <div
      sx={{
        display: [isSelected ? "block" : "none", "block"],
        letterSpacing: "0.03em",
        px: 4,
        mt: 5,
      }}
    >
      {description}
      <div
        sx={{
          mt: 5,
          borderBottom: "1px solid",
        }}
      ></div>
    </div>
  </div>
)

const ProductIngredients = ({ items, isSelected }) => (
  <div
    sx={{
      display: [isSelected ? "block" : "none", "block"],
      px: 4,
      mt: 5,
    }}
  >
    <Heading
      as="h3"
      sx={{
        fontFamily: "monospace",
        fontSize: 2,
        textTransform: "uppercase",
      }}
    >
      <Trans i18nKey="ProductIngredients.title" />
    </Heading>
    <div
      dangerouslySetInnerHTML={{
        __html: items,
      }}
      sx={{
        letterSpacing: "0.03em",
        ul: {
          listStyle: "none",
          mb: 0,
          pl: 0,
          "> li:before": {
            content: `"•"`,
            pr: 2,
          },
          "> li:last-of-type": {
            pb: 5,
            borderBottom: "1px solid",
          },
        },
      }}
    />
  </div>
)

const ProductAdvantages = ({ items, isSelected }) => (
  <div
    sx={{
      display: [isSelected ? "block" : "none", "block"],
      px: 4,
    }}
  >
    <Heading
      as="h3"
      sx={{
        mt: [6, 0],
        fontSize: 28,
        letterSpacing: "0.03em",
      }}
    >
      <Trans i18nKey="ProductAdvantages.title" />
    </Heading>
    {items.map((usp, index) => (
      <div
        key={index}
        sx={{
          ":last-of-type": {
            pb: 4,
            borderBottom: "1px solid",
          },
        }}
      >
        <Heading
          as="h4"
          sx={{
            mt: 5,
            fontFamily: "monospace",
            fontSize: 2,
            textTransform: "uppercase",
          }}
        >
          {`${index + 1}`.padStart(2, "0")} {usp.title}
        </Heading>
        <div
          sx={{
            mt: 3,
            letterSpacing: "0.03em",
          }}
        >
          {usp.description}
        </div>
      </div>
    ))}
  </div>
)

const ProductCard = ({
  isSelected,
  secondaryTitle: subtitle,
  totalPrice: price,
  details: {
    title,
    description,
    ingredients,
    advantages: { items: advantages },
    image,
    imageDescription,
    imageBackgroundColor,
  },
  svgAttributes: benefits,
  ...props
}) => (
  <div
    sx={{
      display: "grid",
      gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
      rowGap: [0, 5],
      columnGap: [undefined, 7],
      px: 4,
      pt: 5,
      pb: [0, 5],
      boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
    }}
    {...props}
  >
    <Heading
      sx={{
        fontSize: [32, 38],
        letterSpacing: "0.03em",
        gridColumn: [undefined, "1 / 3"],
      }}
    >
      {title}
    </Heading>
    <div>
      <div
        sx={{
          display: ["block", "none"],
          fontFamily: "monospace",
          fontSize: [1, 10],
          letterSpacing: "0.15em",
          mt: 2,
          mb: 3,
        }}
      >
        {subtitle}
        <span
          sx={{
            pl: 1,
            ":before": {
              content: `"•"`,
              fontWeight: "bold",
              pr: 1,
            },
          }}
        >
          {price}
        </span>
      </div>
      <ProductDetails
        description={description}
        benefits={benefits}
        image={image}
        imageDescription={imageDescription}
        imageBackgroundColor={imageBackgroundColor}
        isSelected={isSelected}
      />
      <ProductIngredients items={ingredients} isSelected={isSelected} />
    </div>
    <ProductAdvantages items={advantages} isSelected={isSelected} />
  </div>
)

const ProductCards = ({ title, items }) => {
  const { t } = useTranslation()
  const isMobile = useBreakpointIndex() === 0
  const [selected, setSelected] = useState()

  useEffect(() => {
    if (!isMobile && typeof selected !== "number") {
      setSelected(0)
    }
  }, [isMobile, selected])

  return (
    <div>
      <Container noMobilePadding>
        <Heading
          sx={{
            textAlign: "center",
            fontSize: [32, 38],
            letterSpacing: "0.03em",
          }}
        >
          {title}
        </Heading>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["auto", "350px auto"],
            rowGap: [undefined, 5],
            columnGap: [undefined, 5],
            mt: [5, 7],
            mb: [5, 0],
          }}
        >
          {items.map(({ product }, index) => (
            <Fragment key={index}>
              <ProductButton
                title={
                  !isMobile
                    ? product.details.title
                    : index === selected
                    ? t("ProductCards.backButton")
                    : t("ProductCards.ingredientsButton")
                }
                subtitle={!isMobile ? product.secondaryTitle : undefined}
                price={!isMobile ? product.totalPrice : undefined}
                isLeftArrow={isMobile ? index === selected : undefined}
                onClick={() => {
                  if (isMobile && index === selected) {
                    setSelected(undefined)
                  } else {
                    setSelected(index)
                  }
                }}
                sx={{
                  gridColumnStart: [undefined, 1],
                  order: [2 * index + 2, 2 * index + 1],
                  mb: [index !== items.length - 1 ? 5 : undefined, 0],
                }}
              />

              <div
                sx={{
                  gridColumnStart: [undefined, 2],
                  gridRow: [undefined, `1 / span ${items.length + 1}`],
                  order: [2 * index + 1, 2 * index + 2],
                  display: [undefined, index === selected ? undefined : "none"],
                }}
              >
                <ProductCard
                  {...product}
                  isSelected={index === selected}
                  sx={{
                    mb: [undefined, 5],
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default ProductCards

export const productCardsFragment = graphql`
  fragment ProductCardsFragment on Strapi_ComponentBlocksProductCards {
    title
    items {
      product {
        secondaryTitle
        totalPrice
        details {
          title
          description
          ingredients
          advantages {
            items {
              title
              description
            }
          }
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(height: 240, layout: CONSTRAINED)
              }
            }
          }
          imageDescription
          imageBackgroundColor
        }
        svgAttributes {
          image {
            url
          }
          alternateText
        }
      }
    }
  }
`
