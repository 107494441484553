/** @jsx jsx */
import { graphql } from "gatsby"

import {
  Button,
  Container,
  HStack,
  Heading,
  Link,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import { BadgeItem } from "./badges"

const ContentCtaBadges = ({
  title,
  content,
  badges,
  primaryCta,
  secondaryCta,
}) => (
  <div sx={{ mt: [9, 8], mb: [9, 10] }}>
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
        gap: [7, 5],
      }}
    >
      <div sx={{ gridColumn: [null, "6 / span 7"], order: [null, 1] }}>
        <Heading size="lg" mb={[3, 4]}>
          {title}
        </Heading>

        <UserGeneratedHtml html={content} sx={{ mb: [null, 7] }} />

        <HStack sx={{ display: ["none", "flex"] }}>
          {secondaryCta && (
            <Button variant="outline" as={Link} href={secondaryCta.targetUrl}>
              {secondaryCta.label}
            </Button>
          )}
          <Button
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: primaryCta.targetUrl,
              }).click()
            }}
          >
            {primaryCta.label}
          </Button>
        </HStack>
      </div>

      <div sx={{ gridColumn: [null, "span 4"] }}>
        <div sx={{ display: "grid", gap: 6, mb: [7, 0] }}>
          {badges.map((badge) => (
            <BadgeItem
              key={badge.title}
              title={badge.title}
              description={badge.description}
              image={badge.image}
              width={badge.width}
            />
          ))}
        </div>
        <div
          sx={{
            display: ["grid", "none"],
            gap: 4,
          }}
        >
          <Button
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: primaryCta.targetUrl,
              }).click()
            }}
          >
            {primaryCta.label}
          </Button>
          {secondaryCta && (
            <Button
              variant="outline"
              as={Link}
              href={secondaryCta.targetUrl}
              sx={{
                ml: [null, 5],
                mt: [4, 0],
              }}
            >
              {secondaryCta.label}
            </Button>
          )}
        </div>
      </div>
    </Container>
  </div>
)

export default ContentCtaBadges

export const contentCtaBadgesFragment = graphql`
  fragment ContentCtaBadgesFragment on Strapi_ComponentLandingContentCtaBadges {
    title
    content
    primaryCta {
      label
      targetUrl
    }
    secondaryCta {
      label
      targetUrl
    }
    badges {
      title
      description
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      width
    }
  }
`
