/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image"

import { FaqContainer as FaqSection } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const image = (
  <StaticImage
    src="../images/treatment-products.jpg"
    alt="treatment products"
  />
)

const FaqContainer = ({ children }) => (
  <FaqSection image={image}>{children}</FaqSection>
)

export default FaqContainer
