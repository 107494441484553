/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { useMemo } from "react"

import {
  CdnImage,
  Container,
  HStack,
  Heading,
  TextLink,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Slugs } from "@trueskin-web/translations"

const BoardMember = ({
  name,
  slug,
  hasPublicPage,
  image,
  certification,
  className,
}) => {
  const Component = useMemo(
    () =>
      hasPublicPage && slug
        ? (props) => (
            <TextLink
              href={`${Slugs["our-doctors"].url}/${slug}`}
              isTextColor
              hasArrowRight
              {...props}
            />
          )
        : "div",
    [hasPublicPage, slug]
  )

  return (
    <Component
      sx={{
        display: "flex",
        alignItems: "center",
        pb: 4,
        borderBottom: "1px",
      }}
      className={className}
    >
      {image && (
        <GatsbyImage
          image={image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            width: 64,
            height: 64,
          }}
        />
      )}

      <div
        sx={{
          display: "flex",
          flex: 1,
          marginLeft: 4,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Heading as="h4" size="sm" mb={1}>
            {name}
          </Heading>
          <div sx={{ fontSize: 1 }}>Boards-Certified {certification}</div>
        </div>
      </div>
    </Component>
  )
}

const BoardMembersContent = ({ title, content, badges, members }) => (
  <div sx={{ variant: "layout.sectionMargin" }}>
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", "5fr 7fr"],
        alignItems: "flex-start",
        gap: 7,
      }}
    >
      <div sx={{ order: [null, 1] }}>
        <Heading size="lg" mb={[4, 5]}>
          {title}
        </Heading>

        <UserGeneratedHtml html={content} sx={{ mb: 5 }} />

        <HStack>
          {badges.map((badge, index) => (
            <CdnImage
              key={index}
              src={getSrc(badge.image.imageFile)}
              alt={badge.title}
              sx={{
                maxWidth: badge.width,
              }}
            />
          ))}
        </HStack>
      </div>

      <div>
        {members.map((member, index) => (
          <BoardMember
            key={index}
            sx={{
              "&:not(:last-of-type)": {
                mb: 6,
              },
            }}
            {...member}
          />
        ))}
      </div>
    </Container>
  </div>
)

export default BoardMembersContent

export const boardMembersContentFragment = graphql`
  fragment BoardMembersContentFragment on Strapi_ComponentBlocksBoardMembersContent {
    title
    content
    members {
      name
      slug
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 64, aspectRatio: 1, layout: CONSTRAINED)
          }
        }
      }
      certification
      hasPublicPage
    }
    badges {
      title
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      width
    }
  }
`
