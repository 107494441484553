/** @jsx jsx */
import { Link, graphql } from "gatsby"

import { Button, Container } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import AppStoreButtons from "./components/app-store-buttons"

export const gridAlign = (alignment) => {
  switch (alignment) {
    case "left":
      return 1
    case "center":
      return 2
    case "right":
      return 3
    default:
      return null
  }
}

const CtaBlock = ({
  aBackgroundColor: backgroundColor,
  isFullWidthMobile,
  isFullWidthDesktop,
  desktopAlignment,
  variant,
  doNotTrack,
  openInNewWindow,
  label,
  targetUrl,
  aDescription: description,
  appleStoreURL,
  googleStoreURL,
}) => (
  <div
    sx={{
      backgroundColor: [
        isFullWidthMobile ? backgroundColor : "transparent",
        isFullWidthDesktop ? backgroundColor : "transparent",
      ],
    }}
  >
    <Container>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(3, 1fr)"],
          backgroundColor: [
            isFullWidthMobile ? "transparent" : backgroundColor,
            isFullWidthDesktop ? "transparent" : backgroundColor,
          ],
          "> *": {
            gridColumnStart: [undefined, gridAlign(desktopAlignment)],
          },
        }}
      >
        <Button
          as={doNotTrack ? undefined : Link}
          href={doNotTrack ? undefined : targetUrl}
          target={doNotTrack || !openInNewWindow ? undefined : "_blank"}
          onClick={
            doNotTrack
              ? () => {
                  Object.assign(document.createElement("a"), {
                    target: openInNewWindow ? "_blank" : "_self",
                    href: targetUrl,
                  }).click()
                }
              : undefined
          }
          variant={variant}
        >
          {label}
        </Button>
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          sx={{
            mt: [1, 2],
            fontSize: 1,
            textAlign: "center",
            "p, ul, ol": {
              my: 0,
            },
            ul: {
              listStyle: "none",
              pl: 0,
            },
            "ul > li:before": {
              content: `"•"`,
              fontSize: 2,
              pr: 2,
            },
          }}
        />
        {appleStoreURL && googleStoreURL ? (
          <AppStoreButtons
            appleStoreURL={appleStoreURL}
            googleStoreURL={googleStoreURL}
            sx={{ mx: "auto" }}
          />
        ) : null}
      </div>
    </Container>
  </div>
)

export default CtaBlock

export const ctaBlockFragment = graphql`
  fragment CtaBlockFragment on Strapi_ComponentBlocksCtaBlock {
    aBackgroundColor: backgroundColor
    isFullWidthMobile
    isFullWidthDesktop
    desktopAlignment
    variant
    doNotTrack
    openInNewWindow
    label
    targetUrl
    aDescription: description
    appleStoreURL
    googleStoreURL
  }
`
