/** @jsx jsx */
import { formatCurrency, formatCurrencySymbol } from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"

const ContentPrice = ({
  price,
  priceCurrency,
  color,
  backgroundColor,
  csx,
}) => {
  const priceOffer = parseFloat(price).toFixed(2)

  if (isNaN(priceOffer)) {
    return null
  }

  return (
    <div
      sx={{
        display: "inline-block",
        position: "relative",
        "&:after": {
          content: `"${formatCurrency(priceOffer, priceCurrency)}"`,
          fontSize:
            formatCurrencySymbol(priceCurrency).length > 1 ? [3, 5] : [5, 6],
          color: color || "white",
          position: "relative",
        },
        "&:before": {
          content: `""`,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "125%",
          paddingTop: "125%",
          backgroundColor: backgroundColor || "notification",
          borderRadius: "50%",
        },
        ...csx,
      }}
    />
  )
}

export default ContentPrice
