/** @jsx jsx */
import { Fragment } from "react"

import { Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const ContentSection = ({ title, description }) => (
  <Fragment>
    {title && (
      <Heading
        as="h1"
        sx={{
          mb: [4, 0],
          fontSize: [5, 7],
          fontWeight: [600, "normal"],
        }}
      >
        {title}
      </Heading>
    )}
    {description && (
      <div
        dangerouslySetInnerHTML={{ __html: description }}
        sx={{
          fontSize: [1, 2],
          my: 2,
          "ul > li:after": {
            content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' focusable='false' role='presentation'%3E%3Cpath d='M8.333 2.5L3.75 7.083 1.667 5' stroke='%23008878' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' fill='none'%3E%3C/path%3E%3C/svg%3E")`,
            display: "inline-block",
          },
          ol: {
            counterReset: "full-size-hero-counter",
            "& > li": {
              counterIncrement: "full-size-hero-counter",
              "&:after": {
                content: `counter(full-size-hero-counter)`,
              },
            },
          },
          "p, ul, ol": {
            my: 0,
          },
          "ul, ol": {
            listStyle: "none",
            pl: 0,
            ml: 0,
            "& > li": {
              position: "relative",
              mt: [2, 3],
              pl: [5, 6],
              "&:before": {
                content: `""`,
                position: "absolute",
                top: 1,
                left: 0,
                p: 2,
                borderRadius: "50%",
                backgroundColor: "white",
              },
              "&:after": {
                position: "absolute",
                top: 1,
                left: "2px",
                width: "12px",
                height: "12px",
                color: "primary",
                lineHeight: 1,
                textAlign: "center",
                fontSize: 3,
                fontFamily: "monospace",
                fontWeight: 600,
              },
            },
          },
        }}
      ></div>
    )}
  </Fragment>
)

export default ContentSection
