/** @jsx jsx */
import { graphql } from "gatsby"

import { YoutubeEmbed } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const Video = ({ url, source, videoDescription }) => {
  return (
    <div sx={{ my: 7 }}>
      {source === "youtube" && <YoutubeEmbed url={url} />}
      {videoDescription && (
        <p sx={{ mt: 4, mb: 0, fontSize: 1 }}>{videoDescription}</p>
      )}
    </div>
  )
}

export default Video

export const videoFragment = graphql`
  fragment VideoFragment on Strapi_ComponentBlogVideo {
    url
    source
    videoDescription: description
  }
`
