/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fragment } from "react"

import { Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const ZigZag = ({ heading: title, items }) => (
  <div>
    <Heading
      sx={{
        fontSize: 38,
        letterSpacing: "0.03em",
        textAlign: "center",
        mb: 6,
      }}
    >
      {title}
    </Heading>
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
      }}
    >
      {items.map(
        (
          { itemTitle, itemDescription, textBgColor, image, imageBgColor },
          index
        ) => (
          <Fragment key={index}>
            <div
              sx={{
                order: [undefined, index],
                position: "relative",
                minHeight: 220,
                backgroundColor: imageBgColor,
              }}
            >
              <div
                sx={{
                  position: [undefined, "absolute"],
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GatsbyImage
                  image={getImage(image.imageFile)}
                  objectFit="contain"
                  alt=""
                  sx={{
                    maxHeight: 220,
                  }}
                />
              </div>
            </div>
            <div
              sx={{
                display: "inline-grid",
                alignItems: "center",
                order: [undefined, index % 2 ? index - 1 : index],
                py: [6, 9],
                px: 6,
                backgroundColor: textBgColor,
              }}
            >
              <div
                sx={{
                  maxWidth: [undefined, 360],
                  letterSpacing: "0.03em",
                  textAlign: [undefined, index % 2 ? "right" : "left"],
                  marginLeft: [undefined, index % 2 ? "auto" : undefined],
                }}
              >
                <div
                  sx={{
                    mb: 3,
                    textTransform: "uppercase",
                  }}
                >
                  {itemTitle}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: itemDescription,
                  }}
                />
              </div>
            </div>
          </Fragment>
        )
      )}
    </div>
  </div>
)

export default ZigZag

export const zigZagFragment = graphql`
  fragment ZigZagFragment on Strapi_ComponentBlocksZigZag {
    heading: title
    items {
      itemTitle: title
      itemDescription: description
      textBgColor
      imageBgColor
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
