/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useMemo } from "react"

import { Container, Heading, Icon, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { Slugs, Trans } from "@trueskin-web/translations"

const Doctor = ({ name, slug, image, specialty, hasPublicPage }) => {
  const Component = useMemo(
    () =>
      hasPublicPage && slug
        ? (props) => (
            <TextLink
              href={`${Slugs["our-doctors"].url}/${slug}`}
              isTextColor
              {...props}
            />
          )
        : "div",
    [hasPublicPage, slug]
  )

  return (
    <Component
      sx={{
        display: "flex",
        flexDirection: [null, "column"],
        alignItems: ["flex-end", "initial"],
        pb: 5,
        borderBottom: "1px",
      }}
    >
      {image && (
        <GatsbyImage
          image={image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            width: [112, "100%"],
            height: [112, "auto"],
            mb: [null, 4],
          }}
        />
      )}

      <div sx={{ marginLeft: [4, 0], flex: [1, "initial"] }}>
        <div sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading as="h4" mb={1}>
            {name}
          </Heading>
          {hasPublicPage && <Icon icon={arrowRightIcon} />}
        </div>
        <div>{specialty}</div>
      </div>
    </Component>
  )
}

const Doctors = ({ title, description, doctors, withMoreLink }) => (
  <div sx={{ variant: "layout.sectionPadding", bg: "lightRose" }}>
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: ["auto", "5fr 7fr"],
        gap: 7,
      }}
    >
      <div>
        <Heading size="lg" mb={4}>
          {title}
        </Heading>

        <p sx={{ mt: 0, mb: [0, 7], fontSize: [null, 3] }}>{description}</p>

        {withMoreLink && (
          <TextLink
            href={Slugs["our-doctors"].url}
            hasArrowRight
            sx={{ display: ["none", "inline-flex"] }}
          >
            <Trans i18nKey="Doctors.aboutTeamLink" />
          </TextLink>
        )}
      </div>

      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
          rowGap: 7,
          columnGap: [null, 5],
        }}
      >
        {doctors.map((doctor, index) => (
          <Doctor key={index} {...doctor} />
        ))}
      </div>

      {withMoreLink && (
        <TextLink
          href={Slugs["our-doctors"].url}
          hasArrowRight
          sx={{ display: [null, "none"] }}
        >
          <Trans i18nKey="Doctors.aboutTeamLink" />
        </TextLink>
      )}
    </Container>
  </div>
)

export default Doctors

export const doctorsFragment = graphql`
  fragment DoctorsFragment on Strapi_ComponentBlocksDoctors {
    title
    description
    doctors {
      name
      slug
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 300, aspectRatio: 1, layout: CONSTRAINED)
          }
        }
      }
      specialty
      hasPublicPage
    }
  }
`
