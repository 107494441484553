/** @jsx jsx */
import { jsx, useThemeUI } from "@trueskin-web/theme"

const SignaturePlus = ({ size, color, ...props }) => (
  <div
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "monospace",
      width: size,
      height: size,
      color,
    }}
    {...props}
  >
    <div>+</div>
  </div>
)

const SignatureSquare = ({ width, height, size, color, ...props }) => (
  <div
    sx={{
      display: "inline-grid",
      gridTemplateColumns: `repeat(${width}, 1fr)`,
      width: width * size,
      height: height * size,
    }}
    {...props}
  >
    {Array.apply(null, Array(width * height)).map((_, i) => (
      <SignaturePlus
        key={i}
        sx={{
          opacity: 0.2,
        }}
        size={size}
        color={color}
      />
    ))}
  </div>
)

const SignatureImage = ({
  q1 = {
    width: 0,
    height: 0,
  },
  q2 = {
    width: 0,
    height: 0,
  },
  q3 = {
    width: 0,
    height: 0,
  },
  q4 = {
    width: 0,
    height: 0,
  },
  space = 5,
  color = "lightDark",
  ...props
}) => {
  const { theme } = useThemeUI()
  const size = theme.space[space]

  return (
    <div
      sx={{
        display: "grid",
        gridTemplateAreas: `"q4 q1" "q3 q2"`,
      }}
      {...props}
    >
      <SignatureSquare
        sx={{
          gridArea: "q1",
          alignSelf: "end",
          justifySelf: "start",
          transform: `translate(${size / -2}px, ${size / 2}px)`,
        }}
        size={size}
        color={color}
        {...q1}
      />
      <SignatureSquare
        sx={{
          gridArea: "q2",
          alignSelf: "start",
          justifySelf: "start",
          transform: `translate(${size / -2}px, ${size / -2}px)`,
        }}
        size={size}
        color={color}
        {...q2}
      />
      <SignatureSquare
        sx={{
          gridArea: "q3",
          alignSelf: "start",
          justifySelf: "end",
          transform: `translate(${size / 2}px, ${size / -2}px)`,
        }}
        size={size}
        color={color}
        {...q3}
      />
      <SignatureSquare
        sx={{
          gridArea: "q4",
          alignSelf: "end",
          justifySelf: "end",
          transform: `translate(${size / 2}px, ${size / 2}px)`,
        }}
        size={size}
        color={color}
        {...q4}
      />
      <SignaturePlus
        sx={{
          gridArea: "q1",
          alignSelf: "end",
          justifySelf: "start",
          transform: `translate(${size / -2}px, ${size / 2}px)`,
          ":after": {
            content: `""`,
            position: "absolute",
            border: "1px",
            borderRadius: "50%",
            width: size / 2,
            height: size / 2,
            transform: [0, "translateY(-1px)"],
          },
        }}
        size={size}
        color={color}
      />
    </div>
  )
}

export default SignatureImage
