/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { CdnImage, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const ThreePartContent = ({
  title,
  threePartContentDescription: description,
  backgroundColor,
  mainImage,
  secondImage,
  aDesktopAlignment: desktopAlignment,
}) => {
  return (
    <div
      sx={{
        bg: [backgroundColor, "transparent"],
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["auto", "repeat(12, 1fr)"],
          }}
        >
          <div
            sx={{
              position: [undefined, "absolute"],
              top: [undefined, 0],
              bottom: [undefined, 0],
              left: [
                undefined,
                desktopAlignment === "left" ? undefined : "50%",
              ],
              right: [
                undefined,
                desktopAlignment === "left" ? "50%" : undefined,
              ],
            }}
          >
            <GatsbyImage
              image={getImage(mainImage.imageFile)}
              objectPosition={
                desktopAlignment === "left" ? "center right" : "center left"
              }
              alt=""
              sx={{
                height: [undefined, "100%"],
              }}
            />
          </div>
          <div
            sx={{
              pt: [6, 9],
              pb: [0, 9],
              px: [4, 0],
              gridColumn: [
                undefined,
                desktopAlignment === "left" ? "8 / span 4" : "2 / span 4",
              ],
              position: "relative",
              ":before": {
                content: `""`,
                position: "absolute",
                zIndex: -1,
                top: 0,
                bottom: 0,
                left: "-100vw",
                right: "-100vw",
                backgroundColor,
              },
            }}
          >
            <Heading
              sx={{
                fontSize: [32, 38],
                letterSpacing: "0.03em",
              }}
            >
              {title}
            </Heading>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              sx={{
                letterSpacing: "0.03em",
                ul: {
                  pl: 18,
                  "li:not(:first-of-type)": {
                    mt: 2,
                  },
                },
              }}
            />
          </div>
          <div
            sx={{
              gridColumn: [
                undefined,
                desktopAlignment === "left" ? "8 / span 4" : "2 / span 4",
              ],
              pt: [6, 9],
              pb: [0, 9],
              px: [4, 0],
            }}
          >
            <CdnImage
              src={secondImage.url}
              sx={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ThreePartContent

export const threePartContentFragment = graphql`
  fragment ThreePartContentFragment on Strapi_ComponentBlocksThreePartContent {
    title
    threePartContentDescription: description
    backgroundColor
    aDesktopAlignment: desktopAlignment
    mainImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    secondImage {
      url
    }
  }
`
