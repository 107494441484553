/** @jsx jsx */
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import { CdnImage, Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import SignatureImage from "./components/signature-image"

const HeroMedic = ({
  title,
  description,
  note,
  backgroundColor,
  image,
  smallImage,
}) => (
  <div
    sx={{
      display: "flex",
      position: "relative",
      backgroundColor,
    }}
  >
    {smallImage && (
      <CdnImage
        src={getSrc(smallImage.imageFile)}
        alt="doctor"
        sx={{
          display: ["block", "none"],
          position: "absolute",
          right: 0,
          bottom: 0,
          maxHeight: "100%",
        }}
      />
    )}
    <Container
      sx={{
        position: "relative",
        zIndex: 1,
      }}
    >
      {image && (
        <CdnImage
          src={getSrc(image.imageFile)}
          alt="doctor"
          sx={{
            display: ["none", "block"],
            position: "absolute",
            right: 0,
            bottom: 0,
            maxHeight: "100%",
          }}
        />
      )}
      <Heading
        as="h1"
        sx={{
          mt: [5, 6],
          maxWidth: ["66%", "33%"],
          fontSize: [36, 48],
          letterSpacing: "0.03em",
        }}
      >
        {title}
      </Heading>
      <div
        sx={{
          mt: [4, 6],
          mb: [6, 8],
          maxWidth: ["75%", "40%"],
          "*": {
            p: 0,
            m: 0,
            fontSize: [2, 4],
            letterSpacing: "0.03em",
          },
        }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {note && (
        <div
          sx={{
            mb: [6, 7],
            maxWidth: [undefined, "40%"],
            fontFamily: "monospace",
            fontSize: 0,
          }}
        >
          {note}
        </div>
      )}
      <SignatureImage
        q2={{ width: 3, height: 4 }}
        q4={{ width: 3, height: 4 }}
        sx={{
          display: ["none", "grid"],
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-33%, -50%)",
        }}
      />
    </Container>
  </div>
)

export default HeroMedic

export const heroMedicFragment = graphql`
  fragment HeroMedicFragment on Strapi_ComponentBlocksMedicHero {
    title
    description
    note
    backgroundColor
    image {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 1216, layout: CONSTRAINED)
        }
      }
    }
    smallImage {
      url
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
    }
  }
`
