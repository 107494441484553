/** @jsx jsx */
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import {
  Button,
  CdnImage,
  Container,
  Heading,
  StarRating,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { formatDate, textClamp } from "@trueskin-web/functions"
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

import ImageCompare from "./components/image-compare"

const PlatformsReviews = ({ reviews, ...props }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: ["column", "row"],
      justifyContent: "space-evenly",
      alignItems: [null, "flex-end"],
    }}
    {...props}
  >
    {reviews.map(({ platform, rating, reviews }, index) => (
      <div
        key={index}
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          mt: 6,
        }}
      >
        {platform.image && (
          <div
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              height: [28, 32],
              mb: 4,
            }}
          >
            <CdnImage
              src={
                platform.image.imageFile.childImageSharp
                  ? getSrc(platform.image.imageFile)
                  : platform.image.url
              }
              alt={platform.title}
              sx={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </div>
        )}

        <StarRating rating={rating} size="sm" />

        <div
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2,
            letterSpacing: "0.03em",
          }}
        >
          <div
            sx={{
              mr: 4,
            }}
          >
            {rating}/5
          </div>

          <div>
            <Trans i18nKey="PlatformsReviews.reviewsOn" values={{ reviews }} />{" "}
            <Button
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  target: "_blank",
                  href: platform.targetUrl,
                }).click()
              }}
              variant="link"
              size="sm"
              sx={{
                p: 0,
                verticalAlign: "baseline",
              }}
            >
              {platform.title}
            </Button>
          </div>
        </div>
      </div>
    ))}
  </div>
)

const UsersReviews = ({ reviews, ...props }) => (
  <div
    sx={{
      display: "grid",
      gridTemplateColumns: ["auto", "repeat(3, 1fr)"],
      gridGap: [4, 5],
      my: [4, 5],
    }}
    {...props}
  >
    {reviews.map(
      ({ author, images, content, platform, rating, date }, index) => (
        <div
          key={index}
          sx={{
            p: 4,
            boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.08)",
          }}
        >
          {images.length > 1 && (
            <div
              sx={{
                mb: 4,
              }}
            >
              <ImageCompare
                before={images[0]}
                after={images[images.length - 1]}
                showHelper={index === 0}
              />
            </div>
          )}
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 2,
              mb: 4,
            }}
          >
            <div
              sx={{
                fontFamily: "monospace",
              }}
            >
              <div
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {author}
              </div>

              {date && (
                <div
                  sx={{
                    fontSize: 0,
                  }}
                >
                  {formatDate(date, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </div>
              )}
            </div>

            {platform && (
              <div
                sx={{
                  width: 20,
                  height: 20,
                }}
              >
                <CdnImage
                  src={
                    platform.icon.imageFile.childImageSharp
                      ? getSrc(platform.icon.imageFile)
                      : platform.icon.url
                  }
                  alt={`review by ${author}`}
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              </div>
            )}
          </div>

          <div
            sx={{
              display: "flex",
              mx: 2,
              mb: 2,
            }}
          >
            <StarRating rating={rating} size="sm" />
          </div>

          <div
            sx={{
              mx: 2,
              letterSpacing: "0.03em",
            }}
          >
            <input
              type="checkbox"
              id={`social-review-control-${index}`}
              hidden
              sx={{
                ":checked": {
                  "~ label > span, ~ div": {
                    ":first-of-type": {
                      display: "none",
                    },
                    ":not(:first-of-type)": {
                      display: "inline",
                    },
                  },
                },
              }}
            />

            {content.length > 100 && (
              <UserGeneratedHtml
                html={textClamp(`${content.split("><")[0]}>`, 100)}
                sx={{
                  lineHeight: "1.3em",
                }}
              />
            )}
            <UserGeneratedHtml
              html={content}
              sx={{
                display: content.length > 100 ? "none" : undefined,
                lineHeight: "1.3em",
              }}
            />

            <label
              htmlFor={`social-review-control-${index}`}
              sx={{
                display: content.length > 100 ? "inline-block" : "none",
                cursor: "pointer",
                textDecoration: "underline",
                mt: 2,
              }}
            >
              <span>
                <Trans i18nKey="PlatformsReviews.readMore" />{" "}
              </span>
              <span
                sx={{
                  display: "none",
                }}
              >
                <Trans i18nKey="PlatformsReviews.readLess" />
              </span>
            </label>
          </div>
        </div>
      )
    )}
  </div>
)

const SocialReviews = ({
  reviewTitle: title,
  reviewDescription: description,
  reviewsByPlatforms,
  reviewsByUsers,
}) => (
  <div>
    <Container>
      {title && (
        <Heading
          sx={{
            textAlign: "center",
            mt: [4, 6],
            fontSize: [32, 38],
            letterSpacing: "0.03em",
          }}
        >
          {title}
        </Heading>
      )}

      {description && (
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          sx={{
            "*": {
              p: 0,
              m: 0,
            },
            mt: [4, 6],
            fontSize: [2, 4],
            letterSpacing: "0.03em",
          }}
        />
      )}

      {reviewsByPlatforms && <PlatformsReviews reviews={reviewsByPlatforms} />}

      {reviewsByUsers && <UsersReviews reviews={reviewsByUsers} />}
    </Container>
  </div>
)

export default SocialReviews

export const socialReviewsFragment = graphql`
  fragment SocialReviewsFragment on Strapi_ComponentBlocksSocialReviews {
    reviewTitle: title
    reviewDescription: description
    reviewsByPlatforms {
      platform {
        title
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(height: 64, layout: FIXED)
            }
          }
        }
        targetUrl
      }
      rating
      reviews
    }
    reviewsByUsers {
      author
      images {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 350, height: 350, layout: CONSTRAINED)
          }
        }
      }
      content
      platform {
        icon {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 40, height: 40, layout: FIXED)
            }
          }
        }
      }
      rating
      date
    }
  }
`
