import { useEffect, useRef, useState } from "react"

const useImageLoad = () => {
  const ref = useRef()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (ref.current.complete) {
      // img.naturalWidth === 0 ? error : success
      setIsLoaded(true)

      return
    }

    ref.current.onload = () => {
      if (ref.current.complete) {
        // img.naturalWidth === 0 ? error : success
        setIsLoaded(true)
      }
    }

    return () => {
      ref.current.onload = null
    }
  }, [ref])

  return [ref, isLoaded]
}

export default useImageLoad
