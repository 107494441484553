/** @jsx jsx */
import { graphql } from "gatsby"

import { Container, Icon } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

const Milestone = ({ text, tag }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: ["row-reverse", "column"],
      position: "relative",
    }}
  >
    <div
      sx={{
        width: [48, 102],
        mr: 1,
        fontFamily: "monospace",
      }}
    >
      {text}
    </div>
    <div
      sx={{
        borderLeft: ["1px dotted", "1px solid"],
        borderTop: ["1px solid", "none"],
        borderBottom: [undefined, "1px dotted"],
        marginRight: [2, 0],
        marginTop: [0, 1],
        minWidth: [48, 102],
        height: [102, 48],
      }}
    />
    <div
      sx={{
        display: "flex",
        flexDirection: ["row-reverse", "column"],
        position: "absolute",
        top: [0, "auto"],
        bottom: ["auto", 0],
        left: 0,
        transform: ["translateX(-100%)", "translateY(100%)"],
      }}
    >
      <div
        sx={{
          borderTop: ["1px solid", "none"],
          borderLeft: ["none", "1px solid"],
          width: ["14px", "none"],
          height: [undefined, "14px"],
          marginLeft: ["8px", 0],
          marginBottom: [0, "4px"],
        }}
      />
      <div
        sx={{
          fontFamily: "monospace",
          transform: ["translateY(-50%)", "translateX(-50%)"],
          alignSelf: "center",
        }}
      >
        {tag}
      </div>
    </div>
  </div>
)

const Submilestone = ({ text }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: ["row-reverse", "column"],
    }}
  >
    <div
      sx={{
        width: [18, 76],
        mr: 1,
        fontFamily: "monospace",
        fontSize: 0,
      }}
    >
      {text}
    </div>
    <div
      sx={{
        borderLeft: ["1px dotted", "1px dotted"],
        borderTop: ["1px dotted", "none"],
        borderBottom: [undefined, "1px dotted"],
        marginRight: [2, 0],
        marginTop: [0, 1],
        minWidth: [18, 76],
        height: [76, 18],
      }}
    />
  </div>
)

const Timeline = ({ backgroundColor, milestones }) => (
  <div
    sx={{
      backgroundColor,
    }}
  >
    <Container noMobilePadding>
      <div
        sx={{
          position: "relative",
        }}
      >
        <div
          sx={{
            display: ["inline-flex", "flex"],
            flexDirection: ["column", "row"],
            alignItems: ["flex-start", "flex-end"],
            ml: ["50%", 0],
            transform: ["translateX(-50%)", "none"],
            overflowX: [undefined, "auto"],
            paddingBottom: ["12px", "calc(14px + 4px + 1.5 * 16px)"],
            paddingLeft: ["calc(14px + 4px + 1.5 * 16px)", 0],
          }}
        >
          {milestones.map(
            ({ milestoneText, milestoneTag, isSubmilestone }, index) =>
              isSubmilestone ? (
                <Submilestone key={index} text={milestoneText} />
              ) : (
                <Milestone
                  key={index}
                  text={milestoneText}
                  tag={milestoneTag}
                />
              )
          )}
          <div
            sx={{
              flex: 1,
              borderTop: [undefined, "1px dotted"],
            }}
          />
          <Icon
            icon={arrowRightIcon}
            size="xsm"
            sx={{
              position: "absolute",
              right: [undefined, 0],
              bottom: [0, "auto"],
              transform: [
                "rotate(90deg) translate(0, calc(50% - 0.5px))",
                "translate(100%, calc(50% - 0.5px))",
              ],
            }}
          />
        </div>
      </div>
    </Container>
  </div>
)

export default Timeline

export const timelineFragment = graphql`
  fragment TimelineFragment on Strapi_ComponentBlocksTimeline {
    backgroundColor
    milestones {
      milestoneText
      milestoneTag
      isSubmilestone
    }
  }
`
